<template>
    <div class="heatmapwrapper">
        <BarComponent :data="data" :options="barOptions" />
    </div>
</template>
<script>
import 'chart.js/auto';
import { Bar as BarComponent } from 'vue-chartjs';


export default {
    name: 'heatmapchart',
    components: { BarComponent },
    props: ['chartdata','labels'],
    data() {
        return {
            data: {
                labels: ['Sehr hoch', 'Hoch', 'Mittel', 'Gering', 'Sehr gering', 'n/a'],
                datasets: [
                    { 
                        backgroundColor: ['red', 'red', 'yellow', 'green', 'green', 'gray'],
                        data: this.chartdata,
                        tooltiplabels: this.labels, 
                    },
                ],
            },
            barOptions: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'y',
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                return context.dataset.tooltiplabels[context.dataIndex];
                            },
                        }
                    },
                },
            },
        }
    },
}

</script>
