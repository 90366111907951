<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">

        <router-link :to="'/app/users'" href="#">{{ $t("user.header") }} </router-link>
        <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ user.name }}
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-form @submit.prevent="onSubmit" v-show="doneLoading">
            <b-tabs>
                <b-tab :title="$t(`user.tab_editprofile`)" active>
                    <b-card :title="$t(`user.card_editprofile`)" class="mb-3 shadow">
                        <b-card-group >
                            <b-card>
                                <b-form-group :label="$t(`user.company`)" label-for="user-company">
                                    <b-form-input id="user-company" type="text" v-model="user.company" :state="!$v.user.company.$error ? null : false"></b-form-input>
                                </b-form-group>
                                
                                <b-card class="my-4" title="Risikoeinstufung" style="border-color: black; border-width: 3px;">
                                    <b-form-group :label="$t(`user.country_name`)" label-for="courtry">
                                        <vue-multiselect id="country" v-model="user.country" :options="countries" :searchable="true" :close-on-select="true" :show-labels="false" 
                                            track-by="id" label="country_name" :allow-empty="false" placeholder="Land auswählen, tippen für Suche"
                                            :class="!$v.user.country.$error ? '' : 'is-invalid'">
                                            <template slot="singleLabel" slot-scope="{ option }">{{ option.country_name }}</template>
                                        </vue-multiselect>
                                    </b-form-group>

                                    <b-form-group :label="$t(`user.sector_name`)" label-for="sector">
                                        <vue-multiselect id="sector" v-model="user.sector" :options="sectors" :searchable="true" :close-on-select="true" :show-labels="false" 
                                            track-by="id" label="sector_name" :allow-empty="false" placeholder="Branche auswählen, tippen für Suche"
                                            :class="!$v.user.country.$error ? '' : 'is-invalid'">
                                        </vue-multiselect>
                                    </b-form-group> 
                                    <br />
                                    <b-row>
                                        <b-col>
                                            <b-button block class="button-margin pl-4 pr-3 py-0" type="submit" variant="outline-primary"><b-icon icon="save" /></b-button>
                                        </b-col>
                                        <b-col>
                                            <b-button block class="button-margin pl-4 pr-3 py-0" variant="outline-primary" @click="navigateToRisks" ><b-icon icon="arrow-return-left" /></b-button>
                                        </b-col>
                                    </b-row>

                                </b-card>

                                <b-form-group :label="$t(`user.name`)" label-for="user-name">
                                    <b-form-input id="user-name" type="text" v-model="user.name" :state="!$v.user.name.$error ? null : false"></b-form-input>
                                </b-form-group>
                            
                                <b-form-group :label="$t(`user.suppier_number`)" label-for="user-suppier_number">
                                    <b-form-input id="user-suppier_number" type="text" v-model="user.suppier_number"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.password`)" label-for="user-password">
                                    <b-form-input id="user-password" type="password" v-model="user.password"></b-form-input>
                                </b-form-group>
                                
                                <b-form-group :label="$t(`user.passwordrepeat`)" label-for="user-password-repeat">
                                    <b-form-input id="user-password-repeat" type="password" v-model="user.password_repeat" :state="!$v.user.password_repeat.$error ? null : false"></b-form-input>
                                </b-form-group>


                                <b-form-group :label="$t(`user.role`)" label-for="user-role">
                                    <b-form-select text-field="name" value-field="id" :options="roles" v-model="selectedRole" :state="!$v.selectedRole.$error ? null : false"></b-form-select>
                                </b-form-group>
                                
                                <b-form-group :label="$t(`user.lksg_scope`)" label-for="lksg-scope">
                                    <b-form-select v-model="user.lksg_scope" class="mb-3">
                                        <option value="eigener Geschäftsbereich">{{ $t("user.lksg_scope_0") }}</option>
                                        <option value="unmittelbarer Zulieferer">{{ $t("user.lksg_scope_1") }}</option>
                                        <option value="mittelbare Zulieferer">{{ $t("user.lksg_scope_2") }}</option>
                                        <option value="sonstige Benutzer">{{ $t("user.lksg_scope_3") }}</option>
                                    </b-form-select>
                                </b-form-group>

                                <b-form-group :label="$t(`user.contact`)" label-for="user-contact">
                                    <b-form-input id="user-contact" type="text" v-model="user.contact" :state="!$v.user.contact.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.phone`)" label-for="user-phone">
                                    <b-form-input id="user-phone" type="text" v-model="user.phone" :state="!$v.user.phone.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.email`)" label-for="user-email">
                                    <b-form-input id="user-email" type="email" v-model="user.email" :state="!$v.user.email.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.businessunit`)" label-for="user-business-unit">
                                    <b-form-input id="user-business-unit" type="text" v-model="user.business_unit"></b-form-input>
                                </b-form-group>
                            </b-card>
                            <b-card>
                                <b-form-group :label="$t(`user.logo`)" label-for="user-logo">
                                    <template v-if="user.logo">
                                        <b-img :src="user.logo" class="preview-image"></b-img>
                                    </template>
                                    <p class="text-primary" v-if="!user.logo"><strong>{{ $t("user.not_available") }}</strong></p>
                                </b-form-group>

                                <b-form-group :label="$t(`user.logoupload`)" label-for="user-logo">
                                    <b-form-file id="user-logo" v-model="file" placeholder="Logo auswählen..."></b-form-file>
                                </b-form-group>

                                <b-form-group :label="$t(`user.address`)" label-for="user-address">
                                    <b-form-textarea id="user-address" type="text" v-model="user.address" :no-resize="true" :rows="3" :max-rows="3" :state="!$v.user.address.$error ? null : false"></b-form-textarea>
                                </b-form-group>

                                <b-row>
                                    <b-col lg="3">
                                        <b-form-group :label="$t(`user.postal_code`)" label-for="user-postal_code">
                                            <b-form-input id="user-postal_code" type="text" v-model="user.postal_code"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                    <b-col lg="9">
                                        <b-form-group :label="$t(`user.city`)" label-for="user-city">
                                            <b-form-input id="user-city" type="text" v-model="user.city"></b-form-input>
                                        </b-form-group>
                                    </b-col>
                                </b-row>
                                <b-form-group :label="$t(`user.street`)" label-for="user-street">
                                    <b-form-input id="user-street" type="text" v-model="user.street"></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t(`user.webadress`)" label-for="user-webadress">
                                    <b-form-input id="user-webadress" type="text" v-model="user.webadress"></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t(`user.name_2`)" label-for="user-name_2">
                                    <b-form-input id="user-name_2" type="text" v-model="user.name_2"></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t(`user.email_2`)" label-for="user-email_2">
                                    <b-form-input id="user-email_2" type="text" v-model="user.email_2"></b-form-input>
                                </b-form-group>
                                <b-form-group :label="$t(`user.phone_2`)" label-for="user-phone_2">
                                    <b-form-input id="user-phone_2" type="text" v-model="user.phone_2"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.employees`)" label-for="user-employees">
                                    <b-form-input id="user-employees" type="text" v-model="user.employee_count" :state="!$v.user.employee_count.$error ? null : false"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.sales`)" label-for="user-sales">
                                    <b-form-input id="user-sales" type="text" v-model="user.sales"></b-form-input>
                                </b-form-group>

                                <b-form-group :label="$t(`user.productgroup`)" label-for="user-product-group">
                                    <b-form-input id="user-product-group" type="text" v-model="user.product_group"></b-form-input>
                                </b-form-group>

                            </b-card>
                            <b-card>
                                <b-form-group :label="$t(`user.notes`)" label-for="user-notes">
                                    <b-form-textarea id="user-notes" v-model="user.notes" :rows="30"></b-form-textarea>
                                </b-form-group>
                                
                            </b-card>

                        </b-card-group>
                    </b-card>
                </b-tab>
                
                <b-tab :title="$t(`user.tab_selfdisclosure`)">
                    <b-container>
                        <br/>
                        <template v-for="questionnaire in questionnaires">
                            <b-row v-bind:key="questionnaire.id">
                                <b-col>
                                    <QuestionnaireComponent :questionnaire="questionnaire" :answers="answers" />
                                </b-col>
                            </b-row>
                        </template>
                    </b-container>
                </b-tab>
            </b-tabs>
            <br />
            <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="save" />{{ $t("user.btn_save") }}</b-button>
            <b-button class="button-margin" variant="primary" @click="navigateToRisks" ><b-icon icon="arrow-return-left" /> Lieferantenrisiken</b-button>

        </b-form>
    </div>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import userService from '../../../../services/UserService';
import roleService from '../../../../services/RoleService';
import { helpers, required, email, sameAs, numeric } from 'vuelidate/lib/validators';
import QuestionnaireService from '../../../../services/QuestionnaireService';
import QuestionnaireComponent from './Questionnaire';

export default {
    name: 'user-list',
    components: {
        QuestionnaireComponent
    },
    data() {
        return {
            doneLoading: false,
            roles:[],
            questionnaires: [],
            answers: {},
            selectedRole: null,
            user: {
                role: {}
            },
            file: null
        }
    },
    created() {
        this.fetchUser().then(() => {
            this.fetchCountries();
            this.fetchSectors();
            return this.fetchRoles()
                .then(() => {
                    this.$store.commit('loading/setLoading', false);
                    this.doneLoading = true;
                });
        });
    },
    validations: {
        user: {
            name:             {required},
            password_repeat:  {sameAsPassword: sameAs('password')},
            contact:          {required},
            phone:            {required},
            company:          {required},
            address:          {required},
            employee_count:   {required, numeric},
            email:            {required, email},
            country:          {required},
            sector:           {required},
        },
        selectedRole: {
            required
        }
    },
    methods: {
        ...mapActions({
                fetchCountries: 'countries/fetch',
                fetchSectors: 'sectors/fetch'
            }),
        fetchUser() {
            this.$store.commit('loading/setLoading', true);
            return userService.fetch(this.$route.params.id)
                .then(userData => {
                    if(!userData.data.role) {
                        this.selectedRole = 0;
                    }else {
                        this.selectedRole = userData.data.role.id;
                    }
                    this.user = userData.data;

                    return QuestionnaireService.fetchForUser(this.user.id)
                        .then(response => {
                            this.questionnaires = response.data;

                            return QuestionnaireService.fetchAnswersForUser(this.user.id)
                                .then(answers => {
                                    this.answers = answers.data;
                                });
                        });

                });
        },
        fetchRoles() {
            return roleService.list()
                .then(roleData => {
                    this.roles = roleData.data;
                })
        },
        navigateToRisks() {
            this.$router.push('/app/scoring_suppliers');
        },
        onSubmit() {
            this.$v.$touch();

            if(!this.$v.$invalid) {
                this.user.role = this.selectedRole;

                if (this.user.country) {
                    this.user.country_id = this.user.country.id;
                }
                if (this.user.sector) {
                    this.user.sector_id = this.user.sector.id;
                }

                userService.update(this.user.id, this.user, this.file)
                    .then(response => {
                        if(response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notifiy_update_title"),
                                type: 'success',
                                text: this.$t("user.notifiy_update_message"),
                            });
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notifiy_update_error"),
                                type: 'error',
                                text: response.data.message
                            });
                        }
                    });
            }
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            countries: 'countries/countries',
            sectors: 'sectors/sectors',
        }),
    }
};
</script>
