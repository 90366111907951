import store from '../../store/store';
import SectorIndexService from '../../services/Riskmanagement/SectorIndexService';

const state = {
    sectorindices:  []
};

const getters = {
    sectorindices() {
        return state.sectorindices;
    }
};

const actions = {
    fetch({commit}) {
        store.dispatch('loading/setLoading', true);
        return SectorIndexService.list()
            .then(data =>{
                commit("setSectorindices", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, sectorindexId) {
        store.dispatch('loading/setLoading', true);
        return SectorIndexService.delete(sectorindexId)
            .then(data => {
                store.dispatch("sectorindex/fetch");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setSectorIndices', []);
    }
}

const mutations = {
    setSectorIndices(state, sectorindices) {    
        state.sectorindices = sectorindices;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};