import store from '../../store/store';
import CountryService from '../../services/Riskmanagement/CountryService';

const state = {
    countries:  []
};

const getters = {
    countries() {
        return state.countries;
    }
};

const actions = {
    fetch({commit}) {
        store.dispatch('loading/setLoading', true);
        return CountryService.list()
            .then(data =>{
                commit("setCountries", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, countryId) {
        store.dispatch('loading/setLoading', true);
        return CountryService.delete(countryId)
            .then(data => {
                store.dispatch("countries/fetch");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setCountries', []);
    }
}

const mutations = {
    setCountries(state, countries) {    
        state.countries = countries;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};