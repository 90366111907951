import store from '../../store/store';
import SpendService from '../../services/Riskmanagement/SpendService';

const state = {
    spends:  []
};

const getters = {
    spends() {
        return state.spends;
    }
};

const actions = {
    fetch({commit}) {
        store.dispatch('loading/setLoading', true);
        return SpendService.list()
            .then(data =>{
                commit("setSpends", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, spendId) {
        store.dispatch('loading/setLoading', true);
        return SpendService.delete(spendId)
            .then(data => {
                store.dispatch("spends/fetch");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setSpends', []);
    }
}

const mutations = {
    setSpends(state, spends) {    
        state.spends = spends;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};