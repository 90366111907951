<template>
    <div id="app">
        <Navigation />
        <notifications group="global" />
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-md-12 d-flex justify-content-end">
                    <Sidebar />
                    <Sidebar2 />
                    <div class="col-lg-12 col-md-12 ms-sm-auto px-md-3 px-sm-0 mt-3 d-flex flex-column" style="min-height: calc(100vh - 100px)">
                        <div class="flex-wrap pt-3 pb-2 mb-3">
                            <router-view></router-view>
                        </div>
                        <br />
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
        <Loader /> 
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoginComponent from './components/pages/Login';
import Loader from './components/Loader';
import Navigation from './components/Navigation.vue';
import Sidebar from './components/Sidebar.vue';
import Sidebar2 from './components/Sidebar2.vue';
import Footer from './components/pages/backend/footer/Footer.vue';

export default {
    name: 'app',
    data() {
        return {
            appVersion: window.applicationSettings.appVersion,
        }
    },
    components: {
        Loader,
        Navigation,
        Sidebar,
        Sidebar2,
        LoginComponent,
        Footer,
    },
    methods: {
    ...mapActions([])
    }
}
</script>
