<template>
    <div>
        <b-card class="mb-3 shadow" :title="$t(`risk.card_spends`)">
            <b-button class="button-margin" variant="primary" @click.prevent="showNewItemForm" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
                
                <b-iconstack>
                    <b-icon stacked icon="currency-euro"></b-icon>
                    <b-icon stacked icon="plus" shift-h="9" shift-v="3" scale="1"></b-icon>
                </b-iconstack>
                Lieferantenvolumen neu
            </b-button>
            <b-button class="button-margin" variant="outline-primary" @click.prevent="showImport">
                <b-iconstack>
                    <b-icon stacked icon="currency-euro"></b-icon>
                    <b-icon stacked icon="arrow-down" shift-h="9" shift-v="3" scale="0.7"></b-icon>
                </b-iconstack>
                {{ $t("user.btn_userimport") }}
            </b-button>
            <br />
            <b-form-group horizontal :label="$t(`risk.search`)" class="mb-0">
                <b-input-group>
                    <b-input v-model="searchFilter" :placeholder="$t(`risk.search_spends_placeholder`)" />
                    <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t("risk.search_empty") }}</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <br />
            <div class="table-scrollable">
            <b-table striped hover :items="spends" :fields="fields" :filter="searchFilter">
                <template #cell(actions)="data">
                    <b-link class="m-1" href="#" @click="editItem(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                    <b-link class="m-1" href="#" @click="requestConfirmation(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                </template>
            </b-table>
            </div>
            <br />
            <b-modal ref="importModal" hide-footer :title="$t(`user.modal_userimport`)">
                <div class="d-block text-center">
                    {{ $t("user.modal_userimport_notes") }}
                </div>
                <b-form-file ref="excelFileInput" v-model="excelFile" :state="Boolean(excelFile)" :placeholder="$t(`user.placeholder_file`)"></b-form-file>
                <b-button class="button-margin mt-3" block @click="importExcelFile">{{ $t("user.btn_import") }}</b-button>
            </b-modal>
            <b-modal ref="confirmationModal" hide-footer :title="$t(`risk.modal_title_delete`)">
                <div class="d-block text-center">
                    <p v-if="itemContext">{{ $t(`risk.delete_message`, {title: itemContext.spend}) }}</p>
                </div>
                <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="trash" />{{ $t("risk.btn_delete") }}</b-button>
            </b-modal>
        </b-card>
        <Editspend ref="Editspend" :savedCallback="reload" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Editspend from './Editspend';
    import SpendService from '../../../../../services/Riskmanagement/SpendService';
    import importService from '../../../../../services/ImportService';

    export default {
        name: 'spends',
        components: {Editspend},
        props: [],
        data() {
            return {
                searchFilter: null,
                itemContext: null,
                excelFile: null,
            }
        },
        created() {
            this.fetchSpends();
        },
        methods: {
            ...mapActions({
                fetchSpends: 'spends/fetch',
                deleteSpends: 'spends/delete'
            }),
            showNewItemForm() {
                this.currentItemEditMode = false;
                this.$refs.Editspend.setItem({});
                this.$refs.Editspend.showModal();
            },
            editItem(item) {
                this.$refs.Editspend.setItem(item);
                this.$refs.Editspend.showModal();
            this.$refs.Editspend.enterEditMode();
            },
            requestConfirmation(item) {
                this.itemContext = item;
                this.$refs.confirmationModal.show();
            },
            executeDelete() {
                this.$refs.confirmationModal.hide();
                this.$store.commit('loading/setLoading', true);
                SpendService.delete(this.itemContext.id)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Entfernen des Lieferantenvolumens.',
                                type: 'error',
                                text: response.data.message
                            });
                        }

                        this.itemContext = null;
                        this.reload();
                    });
            },
            showImport() {
                this.excelFile = null;
                this.$refs.importModal.show();
            },
            importExcelFile() {
                importService.importSupplierSpend(this.excelFile)
                    .then(response => {
                        if(response.data.success) {
                            this.$refs.importModal.hide();
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notifiy_importuser_title"),
                                type: 'success',
                                text: this.$t("user.notifiy_importuser_message"),
                            });

                            this.fetchSpends();
                        }else {
                            this.$refs.importModal.hide();
                            this.$notify({
                                group: 'global',
                                title: this.$t("user.notifiy_importuser_error"),
                                type: 'error',
                                text: response.data.message
                            });
                            this.fetchSpends();
                        }
                    })
                    .catch(error => {

                    })
            },
            reload() {
                this.fetchSpends();
            },
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                spends: 'spends/spends',
               
            }),
            fields() {
                return [
                    { key: 'id',                label: 'Id'                         , sortable: true  },
                    { key: 'year_id',           label: 'Jahr ID'                    , sortable: true  },
                    { key: 'user_id',           label: 'Benutzer ID'                , sortable: true  },
                    { key: 'spend',             label: 'Lieferantenvolumen'         , sortable: true  },
                    { key: 'analysis_enabled',  label: 'Konkrete Risikoanalyse'                       },
                    { key: 'actions',           label: 'Aktionen'                                     },
                ];
            },
        }
    }
</script>