<template>
    <div>
    <b-card :title="$t('scdda_evaluation.own.header_concrete')" class="mb-3 shadow" v-if="!hasRole('Lieferant')">
        <b-button v-b-toggle.help_concrete pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
        <b-sidebar right id="help_concrete" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
            <div class="px-3 py-2">
                <b-list-group>
                    <b-list-group-item>{{ $t('help.concrete_1') }}</b-list-group-item>
                    <b-list-group-item>{{ $t('help.concrete_2') }}</b-list-group-item>
                    <b-list-group-item>{{ $t('help.concrete_3') }}</b-list-group-item>
                    <b-list-group-item>{{ $t('help.concrete_4') }}</b-list-group-item>
                </b-list-group>
            </div>
        </b-sidebar>

        <b-row>
            <b-col>
                <b-form-group :label="$t('scdda_evaluation.year')" class="mb-3" v-slot="{ ariaDescribedby }">
                    <b-form-select v-model="filterYear" :aria-describedby="ariaDescribedby" class="mt-1">
                        <option v-for="year in years" :key="year.id" :value="year.id">{{ year.year }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>
            <!--<b-col>
                <b-form-group v-model="filterAuction" label="Aktion" class="mb-3" v-slot="{ ariaDescribedby }">
                    <b-form-select v-model="filterAuction" :aria-describedby="ariaDescribedby" class="mt-1" @change="fetchScores">
                        <option v-for="auction in auctions" :key="auction.id" :value="auction.id">{{ auction["title_" + $i18n.locale] }}</option>
                    </b-form-select>
                </b-form-group>
            </b-col>-->
        </b-row>
    </b-card>

    <div v-for="item in filteredSupplierRisks" :key="item.id" v-if="item.analysis_enabled">
        <analysisComponent :user="item" :year="filterYear" />
    </div>
</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../../services/Riskmanagement/RiskService';
import analysisComponent from './EGBAnalysisComponent';

export default {
    name: 'supplierscoretab',
    components: {analysisComponent},
    props: [],
    data() {
        return {
            filterScope: 'eigener Geschäftsbereich',
            filterAuction: null,
            filterYear: null,
            supplierrisks: [],
            selectedYear: null,
        }
    },
    created() {
        this.fetchYears();
        this.fetchSupplierRisks();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchSupplierRisks() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSupplierRisks()
                    .then(response => {
                        this.supplierrisks = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        },
        newlineToBr: function (value) {
                return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        },
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        filteredSupplierRisks() {
            let result = this.supplierrisks.filter(item => {
                const filter1Match = !this.filterScope || item.lksg_scope === this.filterScope;
                const filter2Match = !!this.filterYear && this.filterYear === item.year.id;
                return filter1Match && filter2Match && (item.analysis_enabled == true);
            })
            return result;
        },
    },
}
</script>