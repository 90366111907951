<template>
    <div id="sidebarMenu2" class="col-lg-2 col-md-3 d-md-block sidebar collapse scrollbar">
        <b-row class="text-right">
            <b-col>
                <b-button-close class="mt-4 mr-4" @click="toggleactive2()" text-variant="light">
                    <img src="images/diils_min.png" height="30" class="mb-2" alt="">
                </b-button-close>
            </b-col>
        </b-row>
        <ul class="nav flex-column mb-2 mt-5">
            <li class="nav-item mt-1" v-for="auction in auctions" :key="auction.id">
                <b-link :to="'/app/auktionen/edit/'+auction.id" class="nav-link" href="#">
                    <span class="nav-link-icon"><b-icon icon="eye"></b-icon></span> {{ auction.title }}
                </b-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localeHelper from '../helpers/localeHelper';
import CallForBidsService from '../services/CallForBidsService';
export default {
name: 'callforbids-list',
data() {
    return {
    auctionContext: null,
    isAuction: false,
    type: "auction",
    selectedTenderFields: [],
    }
},
created() {
    this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
    this.type = this.isAuction ? "auction" : "tender";
    this.fetchList();
},
watch: {
    '$route' (to, from) {
        this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
        this.type = this.isAuction ? "auction" : "tender";
        this.fetchList();
    }
},
computed: {
    ...mapGetters({
        hasRole: 'auth/hasRole',
        loggedIn: 'auth/loggedIn',
        currentUser: 'auth/user',
        auctions: 'callForBids/auctions',
        callForBids: 'callForBids/callForBids',
    }),
    auctionFields() {
        let fields = [
            {
                key: 'title',
                label: this.$t(`auction_list.${this.type}.column_title`),
                sortable: true
            }
        ];
        fields.push({
                key: 'actions',
                label: this.$t(`auction_list.${this.type}.column_actions`)
            });

        return fields;
    },
},
methods: {
    ...mapActions({
        logout: 'auth/logout',
        listAuctions: 'callForBids/fetchAuctions',
        listCallForBids: 'callForBids/fetchCallForBids'
    }),
    fetchList() {
        this.isAuction = this.$route.path.indexOf('/app/auktionen') > -1;
        if(this.isAuction) {
            this.listAuctions();
        }else {
            this.listCallForBids();
        }
    },
    editLink(item) {
        return this.isAuction ? '/app/auktionen/edit/' + item.id : '/app/ausschreibung/edit/' + item.id;
    },
    viewLink(item) {
        return this.isAuction ? '/app/auktionen/view/' + item.id : '/app/ausschreibung/view/' + item.id;
    },
    toggleactive2(){
            document.getElementById('sidebarMenu2').classList.toggle('active');
        },
    getAuctionStatusIcon(startDate, endDate) {

        if(!this.$moment(startDate).isBefore(this.$moment())) {
            return 'clock'
        }

        if(this.$moment(endDate).isBefore(this.$moment())) {
            return 'check-circle'
        }

        return 'play-circle-fill';
    },
    getAuctionStatusVariant(startDate, endDate) {

        if(!this.$moment(startDate).isBefore(this.$moment())) {
            return 'success'
        }

        if(this.$moment(endDate).isBefore(this.$moment())) {
            return 'secondary'
        }

        return 'success';
    }
}
}
</script>
  