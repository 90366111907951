<template>
    <b-card class="mb-3 shadow">
        <b-row>
            <b-col sm="auto"><b-link v-b-toggle:[idtoname(user.id)]><span class="when-open"><b-icon icon="chevron-down" /></span><span class="when-closed"><b-icon icon="chevron-right" /></span></b-link></b-col>
            <b-col v-b-toggle:[idtoname(user.id)]>
                <h4>{{ user.display_name }}</h4>
            </b-col>
            <b-col cols="auto">
                <b-button class="button-margin" type="submit" variant="outline-primary" @click="saveInput" v-if="!modified"><b-icon icon="save" />{{ $t("risk.btn_save") }}</b-button>
                <b-button class="button-margin" type="submit" variant="primary" @click="saveInput" v-else><b-icon icon="save" />{{ $t("risk.btn_savechanges") }}</b-button>
            </b-col>
        </b-row>
        <b-collapse :id="idtoname(user.id)" class="showscroll">
                <b-table striped hover :items="riskanalysis" :fields="fields" responsive="sm">
                    <template #cell(score_1_business)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_1_business" placeholder="auswählen" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_2_influence)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_2_influence" placeholder="auswählen" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_3_impact)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_3_impact" placeholder="auswählen" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_4_probability)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_4_probability" placeholder="auswählen" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_5_contribution)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_5_contribution" placeholder="auswählen" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(unadjusted_risk_value)="data">
                        {{ getUnadjustedRiskValue(data.item) }}
                    </template>
                    <template #cell(unadjusted_risk_text)="data">
                        {{ getUnadjustedRiskText(data.item) }}
                    </template>
                    <template #cell(countermeasures)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.countermeasures" placeholder="eingeben" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_1_findings)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.measure_1_findings" placeholder="auswählen" :options="yesno" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(measure_2_sufficient)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.measure_2_sufficient" placeholder="auswählen" :options="yesno" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(measure_3_prportionality)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_3_prportionality" placeholder="eingeben" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_4_feedback)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_4_feedback" placeholder="eingeben" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_5_contextual)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_5_contextual" placeholder="eingeben" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_6_environment)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_6_environment" placeholder="eingeben" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_7_affected)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_7_affected" placeholder="eingeben" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_evaluation)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_evaluation" placeholder="eingeben" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(score_measure_effect)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_measure_effect" placeholder="auswählen" :options="list_measureeffect" value-field="value" text-field="value" @change="setmodified" />
                    </template>
                    <template #cell(adjusted_risk_value)="data">
                        {{ getAdjustedRiskValue(data.item) }}
                    </template>
                    <template #cell(adjusted_risk_text)="data">
                        {{ getAdjustedRiskText(data.item) }}
                    </template>
                    <template #cell(heatmap_value)="data">
                        {{ getHeatmapValue(data.item) }}
                    </template>

                    <!--
                    <template #cell(question)="data">
                        <span :style="(data.item.relevant == 1) ? 'color: black' : 'color: #aaaaaa'">
                            {{ data.value }}
                        </span>
                    </template>
                -->
                </b-table>
        </b-collapse>
    </b-card>
</template>
<script>
import riskAnalysisService from '../../../../../services/Riskmanagement/RiskanalysisService';
export default {
    name: 'analysis-component',
    props: ['user', 'year'],
    data() {
        return {
            loading: true,
            modified: false,
            riskanalysis: [],
            fields: [
                { key: 'questionnaire_title',       label: 'Paragraph', class: "text-nowrap", stickyColumn: true},
                { key: 'score_1_business',          label: 'Art und Umfang der Geschäftstätigkeit' },
                { key: 'score_2_influence',         label: 'Einflussvermögen des Unternehmens auf den unmittelbaren Verursacher' },
                { key: 'score_3_impact',            label: 'Schwere der Verletzung, Umkehrbarkeit der Verletzung' },
                { key: 'score_4_probability',       label: 'Eintrittswahrscheinlichkeit der Verletzung' },
                { key: 'score_5_contribution',      label: 'Grad des Verursachungsbeitrags' },
                { key: 'unadjusted_risk_value',     label: 'Wert Bruttorisiko' },
                { key: 'unadjusted_risk_text',      label: 'Bruttorisiko', tdClass: this.tdClassRisk },
                { key: 'countermeasures',           label: 'alle Präventionsmaßnahmen', },
                { key: 'measure_1_findings',        label: 'Sind die Ergebnisse der regelmäßigen (jährlichen) oder anlassbezogenen Risikoanalysen sowie '
                                                            + 'Erkenntnisse aus Beschwerdeverfahren Ausgangspunkt für die Auswahl der Präventionsmaßnahmen?' },
                { key: 'measure_2_sufficient',      label: 'Sind die im LkSG genannten Präventionsmaßnahmen in Bezug auf die Risiken zielführend und '
                                                            + 'ausreichend oder bedarf es zusätzlicher/anderer Maßnahmen?' },
                { key: 'measure_3_prportionality',  label: 'Wie schwer sind die (möglichen) Verletzungen und wie wahrscheinlich ist ihr Eintritt? Welcher '
                                                            + 'Aufwand in Bezug auf die Präventionsmaßnahmen ist daher nötig? ' },
                { key: 'measure_4_feedback',        label: 'Inwiefern wurden Lernerfahrungen aus der bisherigen Umsetzung von Präventions- und '
                                                            + 'Abhilfemaßnahmen sowie den erfolgten Wirksamkeitsüberprüfungen berücksichtigt?' },
                { key: 'measure_5_contextual',      label: 'Inwiefern wurde bei der Auswahl und der Konzeption von Präventionsmaßnahmen abgewogen, '
                                                            + 'welche Maßnahmen mit Blick auf die Risiken des Unternehmens in den konkreten Kontexten zielführend und ausreichend sind?' },
                { key: 'measure_6_environment',     label: 'Inwiefern wurden hierbei die lokalen rechtlichen, politischen oder kulturellen Gegebenheiten berücksichtigt?' },
                { key: 'measure_7_affected',        label: 'Inwiefern werden die Interessen von (möglicherweise) Betroffenen bei der Auswahl und der '
                                                            + 'Konzeption von Präventionsmaßnahmen identifiziert und ausreichend berücksichtigt?' },
                { key: 'measure_evaluation',        label: 'Bewertung der Gesamtheit der Präventionsmaßnahmen' },
                { key: 'score_measure_effect',      label: 'Wert der Gesamtheit der Präventionsmaßnahmen' },
                { key: 'adjusted_risk_value',       label: 'Wert Nettorisiko' },
                { key: 'adjusted_risk_text',        label: 'Nettorisiko', tdClass: this.tdClassRisk },
                { key: 'heatmap_value',             label: 'Heatmap (Risikomatrix aus Eintrittswahrscheinlichkeit mal Schwere der Verletzung)', tdClass: this.tdClassHeatmap },
            ],
            heatmap: [
                { value: 1, text: 'sehr gering' },
                { value: 2, text: 'gering' },
                { value: 3, text: 'mittel' },
                { value: 4, text: 'hoch' },
                { value: 5, text: 'sehr hoch' },
            ],
            yesno: [
                { value: true, text: 'Ja' },
                { value: false, text: 'Nein' },
            ],
            list_measureeffect: [
                {value: 0},
                {value: 1},
                {value: 2},
                {value: 3},
                {value: 4},
                {value: 5},
            ],
        }
    },
    created() {
        this.loading = true;
            this.$store.commit('loading/setLoading', true);
            riskAnalysisService.fetchAnalysis(this.user.id, this.year)
                .then(response => {
                    this.riskanalysis = response;
                    this.$store.commit('loading/setLoading', false);
                    this.loading = false;
                }).catch(error => {
                    this.riskanalysis = [];
                    this.$store.commit('loading/setLoading', false);
                });
    },
    methods: {
        saveInput(bvModalEvent) {
            this.$store.commit('loading/setLoading', true);
            riskAnalysisService.postAnalysis(this.riskanalysis)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: 'Risikoanalyse',
                            type: 'success',
                            text: 'Eingaben gespeichert.'
                        });
                        this.$store.commit('loading/setLoading', false);
                        this.modified = false;
                    }
                    if(!response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: 'Risikoanalyse',
                            type: 'error',
                            text: response.data.message ?? 'Fehler beim Speichern.'
                        });
                        bvModalEvent.preventDefault();
                        this.$store.commit('loading/setLoading', false);
                    }
                })
                .catch(error => {
                this.$notify({
                    group: 'global',
                    title: 'Risikoanalyse',
                    type: 'error',
                    text: error.message ?? 'Fehler beim Speichern.'
                });
                bvModalEvent.preventDefault();
                this.$store.commit('loading/setLoading', false);
            });
        },
        getUnadjustedRiskValue(item) {
            if (!item.analysis_enabled)
                return null;
            const isOK =
                (!!item.score_1_business)
                && (!!item.score_2_influence)
                && (!!item.score_3_impact)
                && (!!item.score_4_probability)
                && (!!item.score_5_contribution);
            const sum = 
                (item.score_1_business ?? 0)
                + (item.score_2_influence ?? 0)
                + (item.score_3_impact ?? 0)
                + (item.score_4_probability ?? 0)
                + (item.score_5_contribution ?? 0);
            return (isOK) ? Math.round((sum / 5) * 10) / 10 : './.';
            
        },
        getUnadjustedRiskText(item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getUnadjustedRiskValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return null;
            // Exact .5 boundaries don't matter here, 
            // because dividing an integer by 5 won't return a .5 fraction.
            else if (v < 1.5)
                return 'sehr gering';
            else if (v < 2.5)
                return 'gering';
            else if (v < 3.5)
                return 'mittel';
            else if (v < 4.5)
                return 'hoch';
            else
                return 'sehr hoch';
        },
        getAdjustedRiskValue(item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getUnadjustedRiskValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return './.';
            else 
                return Math.round((v - (item.score_measure_effect ?? 0)) * 10) / 10;
        },
        getAdjustedRiskText(item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getAdjustedRiskValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return null;
            // Exact .5 boundaries don't matter here, see above
            else if (v < 1.5)
                return 'sehr gering';
            else if (v < 2.5)
                return 'gering';
            else if (v < 3.5)
                return 'mittel';
            else if (v < 4.5)
                return 'hoch';
            else
                return 'sehr hoch';
        },
        getHeatmapValue(item) {
            if (!item.analysis_enabled)
                return null;
            const product = 
                (item.score_3_impact ?? 0)
                * (item.score_4_probability ?? 0);
            return (product == 0) ? './.' : product;
        },
        tdClassRisk(value, key, item) {
            if (!item.analysis_enabled)
                return null;
            let v = null;
            switch (key) {
                case "unadjusted_risk_text": v = this.getUnadjustedRiskText(item);
                case "adjusted_risk_text": v = this.getAdjustedRiskText(item);
            }
            switch (value) {
                case 'sehr gering': return 'bg-sehrgering';
                case 'gering': return 'bg-gering';
                case 'mittel': return 'bg-mittel';
                case 'hoch': return 'bg-hoch';
                case 'sehr hoch': return 'bg-sehrhoch';
            }
            return '';
        },
        tdClassHeatmap(value, key, item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getHeatmapValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return null;
            else if (v < 5)
                return 'bg-sehrgering';
            else if (v < 15)
                return 'bg-mittel';

            else
                return 'bg-hoch';
        },
        idtoname(id) {
            return `a${id}`;
        },
        setmodified() {
            this.modified = true;
        },
    },
    computed: {
    }
}
</script>
