import axios from 'axios';

export default {
    list(auction, archived, template) {
        let filterParam = '';
    
        if (archived) {
            filterParam = '/archived';
        } else if (template) {
            filterParam = '/template';
        }
    
        if (auction) {
            return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/listAuctions' + filterParam)
                .then(response => response.data)
                .catch(error => error.response);
        } else {
            return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/list' + filterParam)
                .then(response => response.data)
                .catch(error => error.response);
        }
    },
    fetchOrganizers() {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/availableOrganizers')
            .then(response => response.data)
            .catch(error => error.response);
    },
    fetchSelectionLists() {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/selectionLists')
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/create', payload)
            .then(response => response.data)
            .catch(error => error.response);
    },
    update(auctionId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/update/'+ auctionId, payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetch(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/get/'+ auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    moveAuctionUp(auctionId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/moveUp/' + auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveAuctionDown(auctionId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/moveDown/' + auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    delete(auctionId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/callforbids/delete/'+ auctionId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    createAuctionItem(payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/createAuctionItem', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateAuctionItem(payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/updateAuctionItem', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listAuctionItems(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/listAuctionItems/' + auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    uploadFile(auctionId, payload) {
        var formData = new FormData();

        formData.append('name', payload.name);
        formData.append('description', payload.description);
        formData.append('file', payload.file);

        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/' + auctionId + '/file/new', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listFiles(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/listFiles/'+ auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteFile(fileId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/callforbids/deleteFile/'+ fileId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadFile(fileObj) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/downloadFile/'+ fileObj.id, {responseType: 'blob'})
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    editFile(fileUpdateObj) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/editFile/'+ fileUpdateObj.id, fileUpdateObj)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    searchUser(query) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/usersearch/'+ query)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    addUser(auctionId, user) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/' + auctionId + '/assignUser',user)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    unassignUser(auctionId, user) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/' + auctionId + '/unassignUser', user)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getAssignedUsers(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/'+ auctionId + '/assignedUsers')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    newUpload(auctionId, payload) {
        var formData = new FormData();

        formData.append('description', payload.description);
        formData.append('file', payload.file);

        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/' + auctionId + '/upload/new', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    newUploadBids(auctionId, payload) {
        var formData = new FormData();
        formData.append('file', payload.file);

        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/' + auctionId + '/upload/bids', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listUploads(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/listUploads/'+ auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    listBidStatus(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/listBidStatus/'+ auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateBidStatus(payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/updateBidStatus', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadUploadedFile(uploadObject) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/downloadUploadedFile/'+uploadObject.id, {responseType: 'blob'})
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getUserBids(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/'+auctionId+'/userbids')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    bid(auctionId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/'+auctionId+'/bid', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    bidOverview(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/'+auctionId+'/bidoverview')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getHistory(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/'+auctionId+'/history')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getHistoryForItem(auctionItemId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/history/auctionItem/'+ auctionItemId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchActiveAuctions() {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/activeAuctions')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchActiveCallForBids() {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/activeCallForBids')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteAuctionItem(auctionItemId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/callforbids/deleteAuctionItem/'+ auctionItemId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveFileUp(fileId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/file/moveUp/'+ fileId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    moveFileDown(fileId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/file/moveDown/'+ fileId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    deleteUpload(uploadId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/upload/delete/'+ uploadId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    clone(auctionId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/clone/'+ auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetchBidsForAuction(auctionId, offset) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/bids/'+auctionId+'/'+ offset)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    //todo: delteBid
    delteBid(bidId) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/bids/delete/'+ bidId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    updateBid(bidId, payload) {
        return axios.post(window.applicationSettings.apiEndpoint + '/callforbids/bids/update/'+ bidId, payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getUpdatedInfos(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/callforbids/updatedInfos/'+ auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    getQuestionnaireAnswers(auctionId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/questionnaire/fetchAnswersForAuction/' + auctionId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}
