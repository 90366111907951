<template>
    <div>
      <h4>Übersicht Einspareffekte</h4>
      <Bar :data="datacollection" height="100" />
    </div>
</template>

<script>
  
  import 'chart.js/auto';
  import { Bar } from 'vue-chartjs';
  
  export default {
    components: {
      Bar
    },
    data(){
      return {
        datacollection: {
          labels: ['Jan','Feb','Mrz','Apr','Mai','Jun','Jul','Aug','Sep','Okt','Nov','Dez'],
          datasets: [
            {
              label: 'Einsparsumme',
              backgroundColor: '#000120',
              data: [ 20, 30, 40, 50, 55, 70, 80, 90, 100, 105, 105, 120]
            },
          ]
        }
      }
    },
    methods: {
    }
  }
</script>
