import store from '../../store/store';
import YearService from '../../services/Riskmanagement/YearService';

const state = {
    years:  []
};

const getters = {
    years() {
        return state.years;
    }
};

const actions = {
    fetch({commit}) {
        store.dispatch('loading/setLoading', true);
        return YearService.list()
            .then(data =>{
                commit("setYears", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, yearId) {
        store.dispatch('loading/setLoading', true);
        return YearService.delete(yearId)
            .then(data => {
                store.dispatch("years/fetch");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setYears', []);
    }
}

const mutations = {
    setYears(state, years) {    
        state.years = years;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};