<template>
    <div class="card" style="border-width: 0px" v-show="doneLoading">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            <router-link :to="'/app/risikoanalyse'" href="#">{{$t(`campaign_view.maintitle`)}} </router-link>
            <v-icon style="height: 12px" vertical-align="baseline" class="mb-1" name="chevron-right" /> {{ auction["title_" + $i18n.locale] }}
            <router-link v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" :to="'/app/campaign/edit/'+auction.id" href="#"><b-icon icon="pencil-square" class="ml-1" scale="1.2"></b-icon></router-link>
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <p v-if="hasError">{{errorMessage}}</p>
            <b-form v-show="doneLoading && !hasError">
                <b-tabs>
                    <b-tab :title="$t(`campaign_view.tab_overview`)" active>
                        <b-card-group deck>
                            <b-card :title="$t(`campaign_view.headline`)" class="mb-3 shadow">
                                <b-form-group :label="$t(`campaign_view.id`)" v-if="auction.id" label-for="auction-id">
                                    <b-row class="ml-3 text-muted" id="auction-id">{{ auction.id }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`campaign_view.title`)" v-if="auction['title_' + $i18n.locale]" label-for="auction-title">
                                    <b-row class="ml-3 text-muted" id="auction-title">{{ auction["title_" + $i18n.locale] }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`campaign_view.organizer`)" v-if="auction.organizer" label-for="auction-organizer">
                                    <b-row class="ml-3 text-muted" id="auction-organizer">{{ auction.organizer ? auction.organizer.company : '-' }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`campaign_view.start`)" label-for="auction-start">
                                    <b-row class="ml-3 text-muted" id="auction-start">{{ auction.start_date | moment('DD.MM.YYYY HH:mm:ss') }}</b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`campaign_view.end`)" label-for="auction-end">
                                    <b-row class="ml-3 text-muted" id="auction-end">{{ auction.end_date | moment('DD.MM.YYYY HH:mm:ss') }}</b-row>
                                </b-form-group>

                            </b-card>
                            <b-card :title="$t(`campaign_view.headline_contact`)" class="mb-3 shadow">
                                <b-form-group :label="$t(`campaign_view.contact`)" v-if="auction.contact" label-for="auction-contact">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.contact)" id="auction-contact"></b-row>
                                </b-form-group>

                                <b-form-group :label="$t(`campaign_view.techcontact`)" v-if="auction.technical_contact" label-for="auction-technical-contact">
                                    <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.technical_contact)" id="auction-technical-contact"></b-row>
                                </b-form-group>
                            </b-card>
                        </b-card-group>

                        <b-card :title="$t(`campaign_view.notes`)" class="mb-3 shadow" v-if="hasRole('Administrator') || hasRole('Einkäufer') || hasRole('Erweiterter Einkäufer') || hasRole('Berater')">
                            <b-form-group :label="$t(`campaign_view.notes`)" v-if="auction.notes" label-for="auction-notes">
                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.notes)" id="auction-notes"></b-row>
                            </b-form-group>
                        </b-card>

                        <b-card :title="$t(`campaign_view.documents`)" class="mb-3 shadow" v-if="hasRole('Lieferant') && auction.files && auction.files.length > 0">
                            <div class="table-scrollable">
                                <b-table striped hover :items="auction.files" :fields="restrictedFileTableFields" sort-by="order">
                                    <template #cell(actions)="data">
                                        <b-link class="m-1" href="#" @click="downloadFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                    </template>
                                </b-table>
                            </div>
                        </b-card>

                    </b-tab>

                    <b-tab :title="$t(`campaign_view.tab_documents`)" v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer') ">
                        <b-card :title="$t(`campaign_view.documents`)" class="mb-3 shadow">
                            <b-button v-b-toggle.help_documents pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                            <b-sidebar right id="help_documents" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                <div class="px-3 py-2">
                                    <b-list-group>
                                        <b-list-group-item class="user-select-all">{{ $t("help.documents_1") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.documents_2") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.documents_3") }}</b-list-group-item>                                        
                                    </b-list-group>
                                </div>
                            </b-sidebar>
                            <br />
                            <div class="table-scrollable">
                                <b-table striped hover :items="auction.files" :fields="fileTableFields" sort-by="order">
                                    <template #cell(actions)="data">
                                        <b-link class="m-1" href="#" @click="downloadFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                    </template>
                                </b-table>
                            </div>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`campaign_view.tab_selfdisclosure`)" v-if="hasRole('Lieferant')">
                        <b-container>
                            <template v-for="questionnaire in supplierQuestionnaires">
                                <b-row v-bind:key="questionnaire.id">
                                    <b-col>
                                        <SupplierQuestionnaireComponent :questionnaire="questionnaire" :answers="supplierAnswers" />
                                    </b-col>
                                </b-row>
                            </template>
                        </b-container>
                    </b-tab>

                    <b-tab :title="$t(`campaign_view.tab_upload_user`)" v-if="hasRole('Lieferant') && auction.uploads_active">
                        <b-card :title="$t(`campaign_view.card_upload_user`)" :sub-title="$t(`campaign_view.subtitle_upload_user`)" class="mb-3 shadow">
                            <br />
                            <div class="table-scrollable">
                            <b-table striped hover :items="auction.uploads" :fields="uploadTableFields" sort-by="order">
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" @click="downloadUploadedFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                    <b-link class="m-1" href="#" @click="deleteUpload(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                </template>
                                <template #cell(created_at)="data">
                                    {{data.item.created_at | moment('DD.MM.YYYY HH:mm')}}
                                </template>
                            </b-table>
                            </div>
                            <br/>
                            <b-button class="button-margin" variant="primary" @click="showFileForm"><b-icon icon="file-earmark-arrow-up" />{{ $t("campaign_view.btn_upload_user") }}</b-button>
                            <br />
                            <br />
                            <span v-html="$t('campaign_view.notes_upload_user')"></span>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`campaign_view.tab_upload_fromuser`)" v-if="hasRole('Administrator')||hasRole('Einkäufer')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
                        <b-card :title="$t(`campaign_view.card_upload_fromusers`)" class="mb-3 shadow">
                            <div class="table-scrollable">
                                <b-table striped hover :items="auction.uploads" :fields="uploadedTableFields" sort-by="order">
                                    <template #cell(actions)="data">
                                        <b-link class="m-1" href="#" @click="downloadUploadedFile(data.item)"><b-icon icon="download" scale="1.2"></b-icon></b-link>
                                    </template>
                                    <template #cell(created_at)="data">
                                        {{data.item.created_at | moment('DD.MM.YYYY HH:mm')}}
                                    </template>
                                </b-table>
                            </div>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`campaign_view.tab_regulations`)">
                        <b-card :title="$t(`campaign_view.tab_regulations`)" class="mb-3 shadow">
                                                        <!--
                            <b-button v-b-toggle.help_regulations pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                            <b-sidebar right id="help_regulations" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                <div class="px-3 py-2">
                                    <b-list-group>
                                        <b-list-group-item class="user-select-all">{{ $t("help.regulations_1") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.regulations_2") }}</b-list-group-item>
                                        <b-list-group-item v-if="!hasRole('Lieferant')" class="user-select-all">{{ $t("help.regulations_3") }}</b-list-group-item>
                                        <b-list-group-item class="user-select-all">{{ $t("help.regulations_4") }}</b-list-group-item>                                        
                                    </b-list-group>
                                </div>
                            </b-sidebar>
                            -->
                            <br />
                            <b-form-group
                                class="mb-3"
                                v-if="auction['supply_chain_' + $i18n.locale]"
                                >
                                    <b-form-group :label="$t(`campaign_view.supply_chain`)">
                                        <b-row class="ml-3 text-muted" v-html="newlineToBr(auction['supply_chain_' + $i18n.locale])" id="auction-supply-chain"></b-row>
                                    </b-form-group>
                            </b-form-group>
<!--
                            <b-row class="ml-0 mr-2">
                                <b-col md>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_quotation_requirements`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.offer_form || auction.bid_binding_period || auction.currency || auction.award_model"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.offer_form`)" v-if="auction.offer_form" label-for="auction-offer-form">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.offer_form)" id="auction-offer-form"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.bid_binding_period`)" v-if="auction.bid_binding_period" label-for="auction-bid-binding-period">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.bid_binding_period)" id="auction-bid-binding-period"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.currency`)" v-if="auction.currency" label-for="auction-currency">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.currency)" id="auction-currency"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.award_model`)" v-if="auction.award_model" label-for="auction-award-model">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.award_model)" id="auction-award-model"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_qualification_requirements`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.certifications || auction.supply_chain"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.certifications`)" v-if="auction.certifications" label-for="auction-certifications">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.certifications)" id="auction-certifications"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.supply_chain`)" v-if="auction.supply_chain" label-for="auction-supply-chain">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.supply_chain)" id="auction-supply-chain"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_quantityprice_information`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.quantity_details || auction.freight_costs || auction.price_adjustment || auction.bonus_system || auction.other_price_regulations"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.quantity_details`)" v-if="auction.quantity_details" label-for="auction-quantity-details">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.quantity_details)" id="auction-quantity-details"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.freight_costs`)" v-if="auction.freight_costs" label-for="auction-freight-costs">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.freight_costs)" id="auction-freight-costs"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.price_adjustment`)" v-if="auction.price_adjustment" label-for="auction-price-adjustment">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.price_adjustment)" id="auction-price-adjustment"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.bonus_system`)" v-if="auction.bonus_system" label-for="auction-bonus-system">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.bonus_system)" id="auction-bonus-system"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.other_price_regulations`)" v-if="auction.other_price_regulations" label-for="auction-other-price-regulations">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.other_price_regulations)" id="auction-other-price-regulations"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                </b-col>
                                <b-col md>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_logistical_requirements`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.packing_labeling || auction.warehousing || auction.waste_management || auction.returns_process"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.packing_labeling`)" v-if="auction.packing_labeling" label-for="auction-packing-labeling">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.packing_labeling)" id="auction-packing-labeling"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.warehousing`)" v-if="auction.warehousing" label-for="auction-warehousing">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.warehousing)" id="auction-warehousing"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.waste_management`)" v-if="auction.waste_management" label-for="auction-waste-management">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.waste_management)" id="auction-waste-management"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.returns_process`)" v-if="auction.returns_process" label-for="auction-returns-process">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.returns_process)" id="auction-returns-process"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_commercial_processing`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.ordering_process || auction.goods_receipt_process || auction.complaint_management || auction.invoicing"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.ordering_process`)" v-if="auction.ordering_process" label-for="auction-ordering-process">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.ordering_process)" id="auction-ordering-process"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.goods_receipt_process`)" v-if="auction.goods_receipt_process" label-for="auction-goods-receipt-process">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.goods_receipt_process)" id="auction-goods-receipt-process"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.complaint_management`)" v-if="auction.complaint_management" label-for="auction-complaint-management">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.complaint_management)" id="auction-complaint-management"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.invoicing`)" v-if="auction.invoicing" label-for="auction-invoicing">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.invoicing)" id="auction-invoicing"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                    <b-row class="mr-4">
                                        <b-form-group
                                        :label="$t(`cfbcreate.card_tenderterms`)"
                                        label-size="lg"
                                        label-class="font-weight-bold pt-1"
                                        class="mb-3"
                                        v-if="auction.contract_extension || auction.insurances || auction.guarantee_conditions || auction.commercial_conditions || auction.contract_terms"
                                        >
                                            <b-form-group :label="$t(`cfbcreate.contract_extension`)" v-if="auction.contract_extension" label-for="auction-contract-extension">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.contract_extension)" id="auction-contract-extension"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.insurances`)" v-if="auction.insurances" label-for="auction-insurances">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.insurances)" id="auction-insurances"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.guarantee_conditions`)" v-if="auction.guarantee_conditions" label-for="auction-guarantee-conditions">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.guarantee_conditions)" id="auction-guarantee-conditions"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.commercial_conditions`)" v-if="auction.commercial_conditions" label-for="auction-commercial-conditions">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.commercial_conditions)" id="auction-commercial-conditions"></b-row>
                                            </b-form-group>

                                            <b-form-group :label="$t(`cfbcreate.contract_terms`)" v-if="auction.contract_terms" label-for="auction-contract-terms">
                                                <b-row class="ml-3 text-muted" v-html="newlineToBr(auction.contract_terms)" id="auction-contract-terms"></b-row>
                                            </b-form-group>
                                        </b-form-group>
                                    </b-row>
                                </b-col>
                            </b-row>
-->
                        </b-card>
                    </b-tab>
                    <b-tab :title="$t(`campaign_view.tab_user`)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Einkäufer')||hasRole('Erweiterter Einkäufer'))">
                        <b-card :title="$t(`campaign_view.card_user`)" class="mb-3 shadow">
                            <b-button v-b-toggle.help_assigneduser pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                            <b-sidebar right id="help_assigneduser" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                                <div class="px-3 py-2">
                                    <b-list-group>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_1')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_2')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_3')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_4')" /></b-list-group-item>
                                        <b-list-group-item class="user-select-all"><span v-html="$t('help.assigneduser_5')" /></b-list-group-item>                                       
                                    </b-list-group>
                                </div>
                            </b-sidebar>
                            <div class="table-scrollable">
                                <b-table striped hover :items="users" :fields="userTableFields">
                                    <template #cell(accepted_terms_of_service)="data">
                                        <template v-if="data.item.accepted_terms_of_service === true">{{ $t("campaign_view.accepted_tos_true") }}</template>
                                        <template v-if="data.item.accepted_terms_of_service === false">{{ $t("campaign_view.accepted_tos_false") }}</template>
                                    </template>
                                </b-table>
                            </div>
                        </b-card>
                    </b-tab>

                    <b-tab :title="$t(`campaign_view.tab_feedback`)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Einkäufer')||hasRole('Erweiterter Einkäufer')">
                        <template v-for="questionnaire in questionnaireData.questionnaires">
                            <b-row>
                                <b-col>
                                    <AdminQuestionnaireComponent :questionnaire="questionnaire" :questionFields="questionnaireData.questionFields" />
                                </b-col>
                            </b-row>
                        </template>
                    </b-tab>

                </b-tabs>
            </b-form>
        </div>

        <b-modal ref="fileDialog" hide-footer :title="$t(`campaign_view.modal_upload_title`)">
            <b-container>
                <b-form @submit.prevent="onFileUploadSubmit">
                    <b-row>
                        <b-form-group :label="$t(`campaign_view.file_description`)" label-for="file-description">
                            <b-form-textarea id="file-description" v-model="currentFile.description" rows="3" max-rows="3" no-resize />
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-form-group :label="$t(`campaign_view.file_file`)" label-for="file-file">
                            <b-form-file id="file-file" v-model="currentFile.file" :placeholder="$t(`campaign_view.placeholder_upload`)" accept/>
                        </b-form-group>
                    </b-row>
                    <b-row>
                        <b-button class="button-margin" type="submit"><b-icon icon="upload" />{{ $t("campaign_view.btn_upload") }}</b-button>
                    </b-row>
                </b-form>
            </b-container>
        </b-modal>

        <b-modal ref="confirmUploadDeletion" hide-footer :title="$t(`campaign_view.modal_deletedocument_title`)">
            <div class="d-block text-center">
                <p>{{ $t("campaign_view.delete_message1") }} <strong>'{{selectedUpload.filename}}'</strong> {{ $t("campaign_view.delete_message2") }}</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click.prevent="deleteUploadedFile()">{{ $t("campaign_view.btn_delete") }}</b-button>
        </b-modal>
    </div>
</template>
<script>
    import StatusPill from './modules/StatusPill';
    import callForBidsService from '../../../../services/CallForBidsService';
    import QuestionnaireService from '../../../../services/QuestionnaireService';
    import { dateHelper } from '../../../mixins/dateHelper';
    import {mapGetters} from 'vuex';
    import AdminQuestionnaireComponent from './Questionnaire';
    import SupplierQuestionnaireComponent from '../profile/Questionnaire';

    export default {
        name: 'callforbids-view',
        components: {
            AdminQuestionnaireComponent,
            SupplierQuestionnaireComponent,
            StatusPill
        },
        mixins: [dateHelper],
        data() {
            return {
                polling: null,
                hasError: false,
                errorMessage: '',
                currentFile: {
                    description: ''
                },
                doneLoading: false,
                auction: {},
                users: [],
                questionnaireData: {},
                selectedUpload: {},
                selectedGraphItem: 0,
                supplierQuestionnaires: [],
                supplierAnswers: [],
            }
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                auctions: 'callForBids/auctions',
                callForBids: 'callForBids/callForBids',
                currentUser: 'auth/user'
            }),
            userTableFields() {
                return [
                    {
                        key: 'id',
                        label: 'Id'
                    },
                    {
                        key: 'company',
                        label: this.$t(`user.label_company`),
                        sortable: true
                    },
                    {
                        key: 'name',
                        label: this.$t(`user.label_username`),
                        sortable: true
                    },
                    {
                        key: 'email',
                        label: this.$t(`user.label_email`),
                        sortable: true
                    },
                    {
                        key: 'phone',
                        label: this.$t(`user.label_phone`),
                        sortable: true
                    },
                    {
                        key: 'role.name',
                        label: this.$t(`user.label_role`),
                        sortable: true
                    },
                    {
                        key: 'lksg_scope',
                        label: this.$t(`user.lksg_scope`),
                    },
                    {
                        key: 'accepted_terms_of_service',
                        label: this.$t(`user.label_accountactivation`),
                        sortable: true
                    },
                    {
                        key: 'proof_of_entrepreneurial_status',
                        label: this.$t(`user.label_entrepreneur`),
                        sortable: true
                    }
                ];
            },
            fileTableFields() {
                return [
                    {
                        key: 'name',
                        label: this.$t(`campaign_view.label_filename`)
                    },
                    {
                        key: 'description',
                        label: this.$t(`campaign_view.label_filedescription`)
                    },
                    {
                        key: 'filename',
                        label: this.$t(`campaign_view.label_file`)
                    },
                    {
                        key: 'size',
                        label: this.$t(`campaign_view.label_filesize`)
                    },
                    {
                        key: 'actions',
                        label: this.$t(`campaign_view.label_actions`)
                    }
                ];
            },
            uploadTableFields() {
                return [
                    {
                        key: 'filename',
                        label: this.$t(`campaign_view.label_file`)
                    },
                    {
                        key: 'description',
                        label: this.$t(`campaign_view.label_filedescription`)
                    },
                    {
                        key: 'size',
                        label: this.$t(`campaign_view.label_filesize`)
                    },
                    {
                        key: 'created_at',
                        label: this.$t(`campaign_view.label_uploaddate`)
                    },
                    {
                        key: 'actions',
                        label: this.$t(`campaign_view.label_actions`)
                    }
                ];
            },
            uploadedTableFields() {
                return [
                    {
                        key: 'user.name',
                        label: this.$t(`campaign_view.label_username`),
                        sortable: true
                    },
                    {
                        key: 'user.company',
                        label: this.$t(`campaign_view.label_company`),
                        sortable: true
                    },
                    {
                        key: 'filename',
                        label: this.$t(`campaign_view.label_file`),
                        sortable: true
                    },
                    {
                        key: 'description',
                        label: this.$t(`campaign_view.label_filedescription`),
                        sortable: true
                    },
                    {
                        key: 'size',
                        label: this.$t(`campaign_view.label_filesize`),
                        sortable: true
                    },
                    {
                        key: 'created_at',
                        label: this.$t(`campaign_view.label_uploaddate`),
                        sortable: true
                    },
                    {
                        key: 'actions',
                        label: this.$t(`campaign_view.label_actions`)
                    }
                ];
            },
            restrictedFileTableFields() {
                return [
                    {
                        key: 'name',
                        label: this.$t(`campaign_view.label_filename`)
                    },
                     {
                        key: 'description',
                        label: this.$t(`campaign_view.label_filedescription`)
                    },
                    {
                        key: 'filename',
                        label: this.$t(`campaign_view.label_file`)
                    },
                    {
                        key: 'size',
                        label: this.$t(`campaign_view.label_filesize`)
                    },
                    {
                        key: 'actions',
                        label: this.$t(`campaign_view.label_actions`)
                    }
                ];
            },
        },
        created() {
            if(this.$route.params.id) {
                this.$store.commit('loading/setLoading', true);
                callForBidsService.fetch(this.$route.params.id)
                    .then(response => {
                        this.auction = response.data;

                        if(response.data.success === false) {
                            this.hasError = true;
                            this.errorMessage = response.data.message;
                            this.$store.commit('loading/setLoading', false);
                            this.doneLoading = true;
                            return;
                        }

                        if(this.hasRole('Administrator')||this.hasRole('Berater')||this.hasRole('Einkäufer')||this.hasRole('Erweiterter Einkäufer')) {
                            this.fetchAssignedUsers();
                            return callForBidsService.getQuestionnaireAnswers(this.$route.params.id)
                            .then(response => {
                                this.questionnaireData = response.data;
                                this.doneLoading = true;
                                this.$store.commit('loading/setLoading', false);
                            });
                        } else {
                            QuestionnaireService.fetchForMeByAuction(this.$route.params.id)
                                .then(response => {
                                    this.supplierQuestionnaires = response.data;
                                    this.supplierQuestionnaires.sort((a,b)=>(a.order-b.order));

                                    return QuestionnaireService.fetchMyAnswers()
                                        .then(answers => {
                                        this.$store.commit('loading/setLoading', false);
                                        this.supplierAnswers = answers.data;
                                        this.doneLoading = true;
                                        });
                                });

                            this.doneLoading = true;
                            this.$store.commit('loading/setLoading', false);
                        }
                    });
            }
        },
        methods: {
            downloadFile(item) {
                this.$store.commit('loading/setLoading', true);
                callForBidsService.downloadFile(item)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        const url = window.URL.createObjectURL(new Blob([response], {type: item.mimetype}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', item.filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentElement.removeChild(link);
                    });
            },
            downloadUploadedFile(item) {
                this.$store.commit('loading/setLoading', true);
                callForBidsService.downloadUploadedFile(item)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        const url = window.URL.createObjectURL(new Blob([response], {type: item.mimetype}));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', item.filename);
                        document.body.appendChild(link);
                        link.click();
                        link.parentElement.removeChild(link);
                    });
            },
            showFileForm() {
                this.currentFile = {
                    description: ''
                };
                this.$refs.fileDialog.show();
            },
            editLink(item) {
            return '/app/ausschreibung/edit/' + item.id;
            },
            viewLink(item) {
                return '/app/ausschreibung/view/' + item.id;
            },
            onFileUploadSubmit() {
                this.$store.commit('loading/setLoading', true);

                callForBidsService.newUpload(this.auction.id ,this.currentFile)
                    .then(response => {

                        this.$notify({
                                group: 'global',
                                title: this.$t("campaign_view.notify_fileuploadsuccess_title"),
                                type: 'success',
                                text: this.$t("campaign_view.notify_fileuploadsuccess_message")
                            });

                        this.$refs.fileDialog.hide();

                        this.currentFile = {
                            description: ''
                        };

                        callForBidsService.listUploads(this.auction.id)
                            .then(response => {
                                this.auction.uploads = response.data;
                                this.$store.commit('loading/setLoading', false);
                            });
                    })
                    .catch(error => {
                            this.$store.commit('loading/setLoading', false);

                            this.$notify({
                                group: 'global',
                                title: this.$t("campaign_view.notify_fileuploaderror_title"),
                                type: 'error',
                                text: error.message
                            });
                    });
            },
            showUpload() {
                this.$refs.bidUploadModal.showModal();
            },
/*
            formatCurrencyFromInteger(value) {

                var precision = this.auction.precision;

                return numberHelper.toCurrency(value, precision);
            },
            toCurrency(value) {
                return numberHelper.toCurrency(value, this.auction.precision);
            },
            formatThousands(value) {
                return numberHelper.toFormattedStringWithThousands(value);
            },
            isUserColumn(columnName) {
                return columnName.indexOf('user_') > -1;
            },
*/
            newlineToBr: function (value) {
                return value?.replace(/(?:\r\n|\r|\n)/g, '<br />');
            },
            deleteUpload(upload) {
                this.selectedUpload = upload;
                this.$refs.confirmUploadDeletion.show();
            },
            deleteUploadedFile() {
                this.$store.commit('loading/setLoading', true);

                callForBidsService.deleteUpload(this.selectedUpload.id)
                    .then(response => {
                        this.$refs.confirmUploadDeletion.hide();
                        this.selectedUpload = {};

                        if(response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("campaign_view.notify_deleteuploadsuccess_title"),
                                type: 'success',
                                text: this.$t("campaign_view.notify_deleteuploadsuccess_message")
                            });

                            callForBidsService.listUploads(this.auction.id)
                                .then(response => {
                                    this.auction.uploads = response.data;
                                    this.$store.commit('loading/setLoading', false);
                                });
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("campaign_view.notify_deleteuploaderror_title"),
                                type: 'error',
                                text: response.data.message
                            });
                        }
                    });
            },
            fetchAssignedUsers() {
                return callForBidsService.getAssignedUsers(this.auction.id)
                    .then(response => {
                        this.users = response.data;
                    });
            },
        },
        filters: {

        }
    };
    </script>
