<template>
    <b-card :title="$t('scdda_admin.countryindex.header')" class="mb-3 shadow">
        <div class="table-scrollable">
                <b-table striped hover
                :items="countryindices"
                :fields="fields"
                sticky-header="500px"
                responsive="sm"
                >
                </b-table>
        </div>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';

export default {
    name: 'countryindicestab',
    components: {},
    props: [],
    data() {
        return {
            countryindices: [],
            selectedYear: null,
        }
    },
    created() {
        this.fetchYears();
        this.fetchCountryIndices();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchCountryIndices() {
            this.$store.commit('loading/setLoading', true);
                riskService.listCountryIndices()
                    .then(response => {
                        this.countryindices = response.data;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        fields() {
            return [
                { key: 'year.year',                 label: this.$t('scdda_admin.countryindex.col_year') },
                { key: 'country.country_iso_3166',  label: this.$t('scdda_admin.countryindex.col_iso_3166') },
                { key: 'country.country_name_' + this.$i18n.locale,      label: this.$t('scdda_admin.countryindex.col_country'), stickyColumn: true,  isRowHeader: true },
                { key: 'country_risk_sum',          label: this.$t('scdda_admin.countryindex.col_risk_sum'), variant: 'info' },
                { key: 'cpi',                       label: this.$t('scdda_admin.countryindex.col_cpi') },
                { key: 'cpi_risk',                  label: this.$t('scdda_admin.countryindex.col_cpi_risk') },
                { key: 'critw',                     label: this.$t('scdda_admin.countryindex.col_critw') },
                { key: 'critw_risk',                label: this.$t('scdda_admin.countryindex.col_critw_risk') },
                { key: 'gsi',                       label: this.$t('scdda_admin.countryindex.col_gsi') },
                { key: 'gsi_risk',                  label: this.$t('scdda_admin.countryindex.col_gsi_risk') },
                { key: 'nsc',                       label: this.$t('scdda_admin.countryindex.col_nsc') },
                { key: 'nsc_risk',                  label: this.$t('scdda_admin.countryindex.col_nsc_risk') },
                { key: 'wjp',                       label: this.$t('scdda_admin.countryindex.col_wjp') },
                { key: 'wjp_risk',                  label: this.$t('scdda_admin.countryindex.col_wjp_risk') },
                { key: 'ituc',                      label: this.$t('scdda_admin.countryindex.col_ituc') },
                { key: 'ituc_risk',                 label: this.$t('scdda_admin.countryindex.col_ituc_risk') },
                { key: 'hf',                        label: this.$t('scdda_admin.countryindex.col_hf') },
                { key: 'hf_risk',                   label: this.$t('scdda_admin.countryindex.col_hf_risk') },
                { key: 'pf',                        label: this.$t('scdda_admin.countryindex.col_pf') },
                { key: 'pf_risk',                   label: this.$t('scdda_admin.countryindex.col_pf_risk') },
                { key: 'humanrights_risk_sum',      label: this.$t('scdda_admin.countryindex.col_humanrights_risk_sum'), variant: 'info' },
                { key: 'epi',                       label: this.$t('scdda_admin.countryindex.col_epi') },
                { key: 'epi_risk',                  label: this.$t('scdda_admin.countryindex.col_epi_risk') },
                { key: 'environmental_risk_sum',    label: this.$t('scdda_admin.countryindex.col_environmental_risk_sum'), variant: 'info' },
                { key: 'notes',                     label: this.$t('scdda_admin.countryindex.col_notes'), variant: 'secondary' },
            ];
        },
    },
}
</script>