<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        {{ $t('auction_list.campaign.title') }}
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-tabs>
            <b-tab :title="$t('auction_list.campaign.tab_list')" active>
                <b-card class="mb-3 shadow" :title="$t('auction_list.campaign.card_list')">
                    <b-button v-b-toggle.help_createtender pill size="sm" variant="outline-secondary" class="info-button" v-if="!hasRole('Lieferant')"><em>i</em></b-button>    
                    <b-button class="button-margin" variant="primary" :to="newItemLink" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />{{ $t('auction_list.campaign.button_new') }}</b-button>
                    <b-sidebar right id="help_createtender" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                        <div class="px-3 py-2">
                            <b-list-group>
                                <b-list-group-item class="user-select-all">{{ $t("help.createtender_0") }}</b-list-group-item>
                                <b-list-group-item>
                                    <p>{{ $t("help.createtender_1") }}</p>
                                    <p>{{ $t("help.createtender_1a") }}</p>
                                    <p>{{ $t("help.createtender_1b") }}</p>
                                </b-list-group-item>
                                <b-list-group-item>
                                    <p>{{ $t("help.createtender_2") }}</p>
                                    <p>{{ $t("help.createtender_2a") }}</p>
                                    <p>{{ $t("help.createtender_2b") }}</p>
                                </b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_3") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_4") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_5") }}</b-list-group-item>
                                <b-list-group-item>{{ $t("help.createtender_6") }}</b-list-group-item>
                            </b-list-group>
                        </div>
                    </b-sidebar>
                    
                    <br />
                    <b-form-group horizontal :label="$t('auction_list.campaign.search_title')" class="mb-0">
                        <b-input-group>
                            <b-form-tags v-model="searchFilter" :placeholder="$t('auction_list.campaign.search_placeholder')" />
                            <b-input-group-append>
                                <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t('auction_list.campaign.search_reset') }}</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <br />
                    <div class="table-scrollable">
                    <b-table striped hover :items="callForBids" :fields="auctionFields" :filter="searchFilter">
                        <template #cell(title)="data">
                            <div class="d-flex flex-row bd-highlight mb-3 align-self-center">
                                <b-link :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon class="mr-3" v-if="data.item.status === 0" icon="eye-slash" :variant="getAuctionStatusVariant(data.item.start_date, data.item.end_date)" scale="1.5" /></b-link>
                                <b-link :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon class="mr-3" v-if="data.item.status === 1" :icon="getAuctionStatusIcon(data.item.start_date, data.item.end_date)" :variant="getAuctionStatusVariant(data.item.start_date, data.item.end_date)" scale="1.5" /></b-link>
                                <router-link class="mr-3" :to="viewLink(data.item)" href="#">{{data.item["title_" + $i18n.locale]}}</router-link>
                                <div v-if="data.item.ranking_visible === true || data.item.ranking_position_visible === true || data.item.lowest_bid_visible === true">
                                    <v-icon class="mr-1" name="gavel" scale="1.2" />
                                </div>
                                <div class="mr-1" v-if="data.item.price_alert_active === true">
                                    <b-icon icon="bell" scale="1.2"></b-icon>
                                </div>
                            </div>
                        </template>
                        <template #cell(organizer)="data">
                            <template v-if="data.item.organizer">
                                {{data.item.organizer.company}}
                            </template>
                            <template v-if="!data.item.organizer">-</template>
                        </template>
                        <template #cell(sampling_status)="data">
                            <template v-if="data.item.sampling_status === 0">{{ $t(`campaign_view.samplingstatus_0`) }}</template>
                            <template v-if="data.item.sampling_status === 1">{{ $t(`campaign_view.samplingstatus_1`) }}</template>
                            <template v-if="data.item.sampling_status === 2">{{ $t(`campaign_view.samplingstatus_2`) }}</template>
                            <template v-if="data.item.sampling_status === 3">{{ $t(`campaign_view.samplingstatus_3`) }}</template>
                            <template v-if="data.item.sampling_status === 4">{{ $t(`campaign_view.samplingstatus_4`) }}</template>
                        </template>
                        <template #cell(process_status)="data">
                            <template v-if="data.item.process_status === 0">{{ $t(`campaign_view.processstatus_0`) }}</template>
                            <template v-if="data.item.process_status === 1">{{ $t(`campaign_view.processstatus_1`) }}</template>
                            <template v-if="data.item.process_status === 2">{{ $t(`campaign_view.processstatus_2`) }}</template>
                            <template v-if="data.item.process_status === 3">{{ $t(`campaign_view.processstatus_3`) }}</template>
                            <template v-if="data.item.process_status === 4">{{ $t(`campaign_view.processstatus_4`) }}</template>
                            <template v-if="data.item.process_status === 5">{{ $t(`campaign_view.processstatus_5`) }}</template>
                            <template v-if="data.item.process_status === 6">{{ $t(`campaign_view.processstatus_6`) }}</template>
                        </template>
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.id)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" v-show="data.index < callForBids.length-1" @click="moveItemDown(data.item.id)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="viewLink(data.item)"><b-icon icon="eye" scale="1.2"></b-icon></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" @click="requestConfirmation(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" @click.prevent="clone(data.item)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')) && data.item.status === 2"><b-icon icon="folder-plus" scale="1.2"></b-icon></b-link>
                        </template>
                        <template #cell(tasks)="data">
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.basedata_checked ? 'Aktionszeitraum eingestellt' : 'Aktionszeitraum offen'"><b-icon :icon="data.item.basedata_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.conditions_checked ? 'Kontaktinformationen gegeben' : 'Kontaktinformationen noch offen'"><b-icon :icon="data.item.conditions_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
<!--                        <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.items_checked ? 'Ausschreibungspositionen in Bearbeitung' : 'Ausschreibungspositionen in Bearbeitung'"><b-icon :icon="data.item.items_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.bidimport_checked ? 'Multi-Bidding-Einstellungen vorgenommen und mit Angebotsdokument abgeglichen' : 'Multi-Bidding-Einstellungen nicht final'"><b-icon :icon="data.item.bidimport_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.auctionparameter_checked ? 'Aktionsparameter eingestellt' : 'Aktionsparameter noch offen'"><b-icon :icon="data.item.auctionparameter_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
-->
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.questionnaires_checked ? 'Fragebögen zugeordnet' : 'Fragebögen in Bearbeitung'"><b-icon :icon="data.item.questionnaires_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.documents_checked ? 'Dokumente vollständig' : 'Dokumente unvollständig'"><b-icon :icon="data.item.documents_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.userlist_checked ? 'Einladungen versendet' : 'Einladungen offen'"><b-icon :icon="data.item.userlist_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
<!--                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.quotation_requirements_checked ? 'Anforderungen an das Angebot beschrieben' : 'Anforderungen an das Angebot offen'"><b-icon :icon="data.item.quotation_requirements_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
-->                       
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.qualification_requirements_checked ? 'Bearbeitungshinweise beschrieben' : 'Bearbeitungshinweise offen'"><b-icon :icon="data.item.qualification_requirements_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
<!--                            
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.quantityprice_information_checked ? 'Mengen- und Preisinformationen beschrieben' : 'Mengen- und Preisinformationen offen'"><b-icon :icon="data.item.quantityprice_information_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.logistical_requirements_checked ? 'Logistische Anforderungen beschrieben' : 'Logistische Anforderungen offen'"><b-icon :icon="data.item.logistical_requirements_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.commercial_processing_checked ? 'Kaufmännische Abwicklung beschrieben' : 'Kaufmännische Abwicklung offen'"><b-icon :icon="data.item.commercial_processing_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
                            <template><b-badge pill variant="white" class="p-0" v-b-tooltip :title="data.item.tenderterms_checked ? 'Vertragsbedingungen beschrieben' : 'Vertragsbedingungen offen'"><b-icon :icon="data.item.tenderterms_checked ? 'check-circle-fill' : 'check-circle'" font-scale="0.8"></b-icon></b-badge></template>
--> 
                            <!-- ToDo: Zollanforderungen noch integrieren 
                            <b-tooltip target="customs_checked" :title="data.item.customs_checked ? 'Zollanforderungen beschrieben' : 'Zollanforderungen offen'"></b-tooltip>
                            -->
                        </template>
                    </b-table>
                    </div>
                    <br />
                    <b-button class="button-margin" variant="primary" :to="newItemLink" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />{{ $t('auction_list.campaign.button_new') }}</b-button>
                    <b-modal ref="confirmationModal" hide-footer :title="$t('auction_list.campaign.delete_title')">
                        <div class="d-block text-center">
                            <p v-if="auctionContext">{{ $t('auction_list.campaign.delete_message', {title: auctionContext["title_" + $i18n.locale]}) }}</p>
                        </div>
                        <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="trash" />{{ $t('auction_list.campaign.delete_button') }}</b-button>
                    </b-modal>
                </b-card>
            </b-tab>

            <b-tab :title="$t(`questionnaire.card_list`)" v-if="!hasRole('Lieferant')">
                <QuestionnaireList />
            </b-tab>

            <b-tab :title="$t('auction_list.campaign.tab_archived')" v-if="!hasRole('Lieferant')">
                <b-card class="mb-3 shadow" :title="$t('auction_list.campaign.card_archived')">
                    <b-button v-b-toggle.help_archivedtender pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                    <b-sidebar right id="help_archivedtender" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                        <div class="px-3 py-2">
                            <b-list-group>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_1") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_2") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_3") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.archivedtender_4") }}</b-list-group-item>                                
                            </b-list-group>
                        </div>
                    </b-sidebar>
                    <b-form-group horizontal :label="$t('auction_list.campaign.search_title')" class="mb-0">
                    <b-input-group>
                        <b-form-tags v-model="searchFilter" :placeholder="$t('auction_list.campaign.search_placeholder')" />
                        <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t('auction_list.campaign.search_reset') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    </b-form-group>
                    <br />
                    <div class="table-scrollable">
                    <b-table striped hover :items="callForBids_archived" :fields="auctionFields" :filter="searchFilter">
                        <template #cell(title)="data">
                            <router-link :to="'/app/auktionen/view/'+data.item.id" href="#">{{data.item["title_" + $i18n.locale]}}</router-link>
                        </template>
                        <template #cell(organizer)="data">
                            <template v-if="data.item.organizer">
                                {{data.item.organizer.company}}
                            </template>
                            <template v-if="!data.item.organizer">-</template>
                        </template>
                        <template #cell(sampling_status)="data">
                            <template v-if="data.item.sampling_status === 0">{{ $t(`campaign_view.samplingstatus_0`) }}</template>
                            <template v-if="data.item.sampling_status === 1">{{ $t(`campaign_view.samplingstatus_1`) }}</template>
                            <template v-if="data.item.sampling_status === 2">{{ $t(`campaign_view.samplingstatus_2`) }}</template>
                            <template v-if="data.item.sampling_status === 3">{{ $t(`campaign_view.samplingstatus_3`) }}</template>
                            <template v-if="data.item.sampling_status === 4">{{ $t(`campaign_view.samplingstatus_4`) }}</template>
                        </template>
                        <template #cell(process_status)="data">
                            <template v-if="data.item.process_status === 0">{{ $t(`campaign_view.processstatus_0`) }}</template>
                            <template v-if="data.item.process_status === 1">{{ $t(`campaign_view.processstatus_1`) }}</template>
                            <template v-if="data.item.process_status === 2">{{ $t(`campaign_view.processstatus_2`) }}</template>
                            <template v-if="data.item.process_status === 3">{{ $t(`campaign_view.processstatus_3`) }}</template>
                            <template v-if="data.item.process_status === 4">{{ $t(`campaign_view.processstatus_4`) }}</template>
                            <template v-if="data.item.process_status === 5">{{ $t(`campaign_view.processstatus_5`) }}</template>
                            <template v-if="data.item.process_status === 6">{{ $t(`campaign_view.processstatus_6`) }}</template>
                        </template>
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" :to="viewLink(data.item)"><b-icon icon="eye" scale="1.2"></b-icon></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" @click.prevent="clone(data.item)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')) && data.item.status === 2"><b-icon icon="folder-plus" scale="1.2"></b-icon></b-link>
                        </template>
                        <template #cell(status)="data">
                            <b-icon v-if="data.item.status === 2" icon="archive" :variant="getAuctionStatusVariant(data.item.start_date, data.item.end_date)" scale="1.5" />
                        </template>
                    </b-table>
                    </div>
                    <br />
                </b-card>
            </b-tab>

            <b-tab :title="$t('auction_list.campaign.tab_template')" v-if="!hasRole('Lieferant')">
                <b-card class="mb-3 shadow" :title="$t('auction_list.campaign.card_template')">
                    <b-button v-b-toggle.help_templatetender pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                    <b-sidebar right id="help_templatetender" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                        <div class="px-3 py-2">
                            <b-list-group>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_1") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_2") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_3") }}</b-list-group-item>
                                <b-list-group-item class="user-select-all">{{ $t("help.templatetender_4") }}</b-list-group-item>                                
                            </b-list-group>
                        </div>
                    </b-sidebar>
                    <b-form-group horizontal :label="$t('auction_list.campaign.search_title')" class="mb-0">
                    <b-input-group>
                        <b-form-tags v-model="searchFilter" :placeholder="$t('auction_list.campaign.search_placeholder')" />
                        <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t('auction_list.campaign.search_reset') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                    </b-form-group>
                    <br />
                    <div class="table-scrollable">
                    <b-table striped hover :items="callForBids_template" :fields="auctionFields" :filter="searchFilter">
                        <template #cell(title)="data">
                            <router-link :to="'/app/auktionen/view/'+data.item.id" href="#">{{data.item["title_" + $i18n.locale]}}</router-link>
                        </template>
                        <template #cell(organizer)="data">
                            <template v-if="data.item.organizer">
                                {{data.item.organizer.company}}
                            </template>
                            <template v-if="!data.item.organizer">-</template>
                        </template>
                        <template #cell(sampling_status)="data">
                            <template v-if="data.item.sampling_status === 0">{{ $t(`campaign_view.samplingstatus_0`) }}</template>
                            <template v-if="data.item.sampling_status === 1">{{ $t(`campaign_view.samplingstatus_1`) }}</template>
                            <template v-if="data.item.sampling_status === 2">{{ $t(`campaign_view.samplingstatus_2`) }}</template>
                            <template v-if="data.item.sampling_status === 3">{{ $t(`campaign_view.samplingstatus_3`) }}</template>
                            <template v-if="data.item.sampling_status === 4">{{ $t(`campaign_view.samplingstatus_4`) }}</template>
                        </template>
                        <template #cell(process_status)="data">
                            <template v-if="data.item.process_status === 0">{{ $t(`campaign_view.processstatus_0`) }}</template>
                            <template v-if="data.item.process_status === 1">{{ $t(`campaign_view.processstatus_1`) }}</template>
                            <template v-if="data.item.process_status === 2">{{ $t(`campaign_view.processstatus_2`) }}</template>
                            <template v-if="data.item.process_status === 3">{{ $t(`campaign_view.processstatus_3`) }}</template>
                            <template v-if="data.item.process_status === 4">{{ $t(`campaign_view.processstatus_4`) }}</template>
                            <template v-if="data.item.process_status === 5">{{ $t(`campaign_view.processstatus_5`) }}</template>
                            <template v-if="data.item.process_status === 6">{{ $t(`campaign_view.processstatus_6`) }}</template>
                        </template>
                        <template #cell(actions)="data">
                            <b-link class="m-1" href="#" :to="viewLink(data.item)"><b-icon icon="eye" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" :to="editLink(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                            <b-link class="m-1" href="#" @click.prevent="clone(data.item)" v-if="(hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')) && data.item.status === 3"><b-icon icon="folder-plus" scale="1.2"></b-icon></b-link>
                        </template>
                        <template #cell(status)="data">
                            {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                        </template>
                    </b-table>
                    </div>
                    <br />
                </b-card>
            </b-tab>
        </b-tabs>
    </div>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import CallForBidsService from '../../../../services/CallForBidsService';
import { dateHelper } from '../../../mixins/dateHelper';
import QuestionnaireList from '../questionnaires/modules/ListCard';

export default {
    name: 'callforbids-list',
    components: {
        QuestionnaireList,
    },
    mixins: [dateHelper],
    data() {
        return {
            archived:'',
            template:'',
            searchFilter: '',
            auctionContext: null,
            selectedTenderFields: [],
            selectedItemFields: [],
            selectedSupplierFields: []
        }
    },
    created() {
        this.fetchList();
    },
    watch: {
        '$route' (to, from) {
            this.fetchList();
        }
    },
    methods: {
        ...mapActions({
            listCallForBids: 'callForBids/fetchCallForBids',
            listArchivedCallForBids: 'callForBids/fetchArchivedCallForBids',
            listTemplateCallForBids: 'callForBids/fetchTemplateCallForBids',
            deleteAuction: 'callForBids/delete',
            fetchSelectionLists: 'callForBids/fetchSelectionLists'
        }),
        requestConfirmation(auction) {
            this.auctionContext = auction;
            this.$refs.confirmationModal.show();
        },
        moveItemUp(auctionId) {
            this.$store.commit('loading/setLoading', true);
            CallForBidsService.moveAuctionUp(auctionId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchList();
                });
        },
        moveItemDown(auctionId) {
            this.$store.commit('loading/setLoading', true);
            CallForBidsService.moveAuctionDown(auctionId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.fetchList();
                });
        },
        executeDelete() {
            this.$refs.confirmationModal.hide();
            this.deleteAuction(this.auctionContext.id)
                    .then(response => {
                        if (response.status == 200) {
                            this.$notify({
                                group: 'global',
                                title: this.$t('auction_list.campaign.delete_notify_title'),
                                type: 'success',
                                text: this.$t('auction_list.campaign.delete_notify_message')
                            })
                        } else {
                            this.$notify({
                                group: 'global',
                                title: 'Fehler beim Löschen der Vergabe',
                                type: 'error',
                                text: response.data.message
                            });
                        }
                        this.auctionContext = null;
                    });
        },
        fetchList() {
            this.listCallForBids();
            this.listArchivedCallForBids();
            this.listTemplateCallForBids();
        },
        editLink(item) {
            return '/app/campaign/edit/' + item.id;
        },
        viewLink(item) {
            return '/app/campaign/view/' + item.id;
        },
        clone(item) {
            this.$store.commit('loading/setLoading', true);
            CallForBidsService.clone(item.id)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    if(response.data.success) {
                        this.$notify({
                                group: 'global',
                                title: this.$t('auction_list.campaign.clone_success_title'),
                                type: 'success',
                                text: this.$t('auction_list.campaign.clone_success_message')
                            });
                    }else {
                        this.$notify({
                                group: 'global',
                                title: this.$t('auction_list.campaign.clone_error_title'),
                                type: 'error',
                                text: response.data.message
                            });
                    }
                });
        },
        getAuctionStatus(startDate, endDate) {

            if(!this.$moment(startDate).isBefore(this.$moment())) {
                return this.$t('auction_list.campaign.status_beforestart')
            }

            if(this.$moment(endDate).isBefore(this.$moment())) {
                return this.$t('auction_list.campaign.status_finished')
            }

            return this.$t('auction_list.campaign.status_running');
        },
        getAuctionStatusIcon(startDate, endDate) {

            if(!this.$moment(startDate).isBefore(this.$moment())) {
                return 'clock'
            }

            if(this.$moment(endDate).isBefore(this.$moment())) {
                return 'check-circle'
            }

            return 'play-circle-fill';
        },
        getAuctionStatusVariant(startDate, endDate) {

            if(!this.$moment(startDate).isBefore(this.$moment())) {
                return 'success'
            }

            if(this.$moment(endDate).isBefore(this.$moment())) {
                return 'secondary'
            }

            return 'success';
        }
    },
    computed: {
        ...mapGetters({
            callForBids: 'callForBids/callForBids',
            callForBids_archived: 'callForBids/callForBids_archived',
            callForBids_template: 'callForBids/callForBids_template',
            hasRole: 'auth/hasRole',
            usageTypes: 'callForBids/usageTypes'
        }),
        newItemLink() {
            return '/app/campaign/new';
        },
        auctionFields() {
            let fields = [
                {
                    key: 'id',
                    label: this.$t('auction_list.campaign.column_id')
                },
                {
                    key: 'title',
                    label: this.$t('auction_list.campaign.column_title'),
                    sortable: true
                },
                {
                    key: 'organizer',
                    label: this.$t('auction_list.campaign.column_organizer'),
                    sortable: true
                },
                {
                    key: 'usage_type',
                    label: this.$t('auction_list.campaign.column_scope')
                },
                {
                    key: 'start_date',
                    label: this.$t('auction_list.campaign.column_start'),
                    sortable: true,
                    formatter: this.dateTimeFormatter
                },
                {
                    key: 'end_date',
                    label: this.$t('auction_list.campaign.column_end'),
                    sortable: true,
                    formatter: this.dateTimeFormatter
                }
            ];

            if(this.hasRole('Administrator') || this.hasRole('Berater') || this.hasRole('Erweiterter Einkäufer')) {
                fields.push({
                    key: 'sampling_status',
                    label: this.$t('auction_list.campaign.column_sampling_status'),
                    sortable: true
                });
                fields.push({
                    key: 'process_status',
                    label: this.$t('auction_list.campaign.column_process_status'),
                    sortable: true
                });
                fields.push({
                    key: 'tasks',
                    label: 'Trackpoints  '
                });
                
            }

            fields.push({
                    key: 'actions',
                    label: this.$t('auction_list.campaign.column_actions')
                });

            return fields;
        },
    }
};
</script>
