import store from '../../store/store';
import QuestionnaireService from '../../services/QuestionnaireService';

const state = {
    questionnaires:  [],
    usageTypes: [],
};

const getters = {
    questionnaires() {
        return state.questionnaires;
    },
    usageTypes() {
        return state.usageTypes;
    },
};

const actions = {
    list({commit}) {
        store.dispatch('loading/setLoading', true);
        return QuestionnaireService.list()
            .then(data =>{
                commit("setQuestionnaires", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    clear({commit}) {
        commit('setQuestionnaires', []);
        commit('setUsageTypes', []);
    },
    fetchSelectionLists({commit}) {
        store.dispatch('loading/setLoading', true);
        return QuestionnaireService.fetchSelectionLists()
            .then(data => {
                commit('setUsageTypes', data.data.usageTypes);
                store.dispatch('loading/setLoading', false);
            });
    },
}

const mutations = {
    setQuestionnaires(state, questionnaires) {    
        state.questionnaires = questionnaires;
    },
    setUsageTypes(state, usageTypes) {
        state.usageTypes = usageTypes;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};