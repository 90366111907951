<template>
    <b-card :title="$t('scdda_admin.sectorindex.header')" class="mb-3 shadow">
        <div class="table-scrollable">
                <b-table striped hover
                sticky-header="500px"
                :items="sectorindices"
                :fields="fields"
                responsive="sm"
                >
                </b-table>
        </div>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';

export default {
    name: 'sectorindicestab',
    components: {},
    props: [],
    data() {
        return {
            sectorindices: [],
            selectedYear: null,
        }
    },
    created() {
        this.fetchYears();
        this.fetchSectorIndices();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        fetchSectorIndices() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSectorIndices()
                    .then(response => {
                        this.sectorindices = response.data;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        }
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years'
        }),
        fields() {
            return [
                //{ key: 'id', sortable: true },
                { key: 'year.year', label: this.$t('scdda_admin.sectorindex.col_year') },
                { key: 'sector.sector_code_nace', label: this.$t('scdda_admin.sectorindex.col_nace') },
                { key: 'sector.sector_name_' + this.$i18n.locale, label: this.$t('scdda_admin.sectorindex.col_sector') },
                { key: 'research_report', label: this.$t('scdda_admin.sectorindex.col_report') },
                { key: 'es_supply_chain_act', label: this.$t('scdda_admin.sectorindex.col_eu') },
                { key: 'sector_risk_sum', label: this.$t('scdda_admin.sectorindex.cocol_riskl_') },
                { key: 'notice', label: this.$t('scdda_admin.sectorindex.col_notice') },
            ];
        },
    },
}
</script>