import store from '../../store/store';
import SectorService from '../../services/Riskmanagement/SectorService';

const state = {
    sectors:  []
};

const getters = {
    sectors() {
        return state.sectors;
    }
};

const actions = {
    fetch({commit}) {
        store.dispatch('loading/setLoading', true);
        return SectorService.list()
            .then(data =>{
                commit("setSectors", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, sectorId) {
        store.dispatch('loading/setLoading', true);
        return SectorService.delete(sectorId)
            .then(data => {
                store.dispatch("sectors/fetch");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setSectors', []);
    }
}

const mutations = {
    setSectors(state, sectors) {    
        state.sectors = sectors;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};