<template>
    <div class="heatmapwrapper">
        <BubbleComponent :data="{ datasets: [{ labels: labels, data: chartdata }]}" :options="bubbleOptions" :plugins="plugins" />
    </div>
</template>
<script>
import 'chart.js/auto';
import { Bubble as BubbleComponent } from 'vue-chartjs';


export default {
    name: 'heatmapchart',
    components: { BubbleComponent },
    props: ['chartdata','labels'],
    data() {
        const svg = '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg"><rect width="100" height="100" x="0" y="0" fill="yellow" /><rect width="40" height="40" x="0" y="60" fill="green" />'
            + '<rect width="40" height="20" x="40" y="80" fill="green" /><rect width="20" height="40" x="0" y="20" fill="green" /><rect width="40" height="40" x="60" y="0" fill="red" />'
            + '<rect width="20" height="20" x="40" y="0" fill="red" /><rect width="20" height="20" x="80" y="40" fill="red" /></svg>';
        const image = new Image();
        image.src = 'data:image/svg+xml,' + encodeURIComponent(svg);;

        const plugin = {
            id: 'customCanvasBackgroundImage',
            beforeDraw: (chart) => {
                if (image.complete) {
                    const ctx = chart.ctx;
                    const {top, left, width, height} = chart.chartArea;
                    //const x = left + width / 2 - image.width / 2;
                    //const y = top + height / 2 - image.height / 2;
                    ctx.drawImage(image, left, top, width, height);
                } else {
                    image.onload = () => chart.draw();
                }
            }
        };

        return {
            plugins: [plugin],
            bubbleOptions: {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    x: {
                        min: 0,
                        max: 5,
                        ticks: {
                            stepSize: 1.0,
                        },
                        title: {
                            display: true,
                            text: this.$t('scdda_evaluation.chart_severity'),
                        },
                    },
                    y: {
                        min: 0,
                        max: 5,
                        ticks: {
                            stepSize: 1.0,
                        },
                        title: {
                            display: true,
                            text: this.$t('scdda_evaluation.chart_probability'),
                        },
                    },
                },
                plugins: {
                    legend: {
                        display: false,
                    },
                    tooltip: {
                        callbacks: {
                            label: function(context) {
                                //return 'Text comes here';
                                return context.dataset.labels[context.dataIndex];
                                //return label + ': (' + tooltipItem.xLabel + ', ' + tooltipItem.yLabel + ')';
                            },
                            labelColor: function(context) {
                                const { x, y } = context.parsed;
                                const value = (x + 0.5) * (y + 0.5);
                                var color = 'yellow';
                                if (value <= 4) {
                                    color = 'green';
                                } else if (value >= 15) {
                                    color = 'red';
                                }
                                return {
                                    backgroundColor: color,
                                };
                            },
                        }
                    },
                },
            },
        }
    },
}

</script>
