<template>
    <b-card title="Lieferantenrisiken - Abstrakte Risikoanalyse unmittelbare Zulieferer" class="mb-3 shadow" v-if="!hasRole('Lieferant')"">
        <b-button v-b-toggle.help_risks pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
        <b-sidebar right id="help_risks" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
            <div class="px-3 py-2">
                <b-list-group>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                    <b-list-group-item></b-list-group-item>
                </b-list-group>
            </div>
        </b-sidebar>

        <b-card-group deck class="my-4">
            
            <b-card title="Filtereinstellungen" border-variant="secondary" class="mb-3">
                <b-form-group label="Filter Jahre" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="filterYear" :aria-describedby="ariaDescribedby" class="mt-1">
                        <b-form-checkbox v-for="year in years" :key="year.id" :value="year.year">{{ year.year }}</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>

                <b-form-group v-model="filterCountryScore" label="Filter Länderrisiko" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="filterCountryScore" :aria-describedby="ariaDescribedby" class="mt-1">
                        <b-form-checkbox value="Hoch">Hoch</b-form-checkbox>
                        <b-form-checkbox value="Mittel">Mittel</b-form-checkbox>
                        <b-form-checkbox value="Niedrig">Niedrig</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>

                <b-form-group v-model="filterSectorScore" label="Filter Branchenrisiko" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox-group v-model="filterSectorScore" :aria-describedby="ariaDescribedby" class="mt-1">
                        <b-form-checkbox value="Hoch">Hoch</b-form-checkbox>
                        <b-form-checkbox value="Mittel">Mittel</b-form-checkbox>
                        <b-form-checkbox value="Niedrig">Niedrig</b-form-checkbox>
                    </b-form-checkbox-group>
                </b-form-group>
                
                <b-form-group label="Fehlende Angaben" v-slot="{ ariaDescribedby }">
                    <b-form-checkbox inline v-model="filterCountryEmpty" value="1" unchecked-value="0">Land</b-form-checkbox>
                    <b-form-checkbox inline v-model="filterSectorEmpty" value="1" unchecked-value="0">Branche</b-form-checkbox>
                </b-form-group>
            </b-card>

            <b-card title="Suche" border-variant="secondary" class="mb-3">
                <b-form-group label="Freitextsuche" v-slot="{ ariaDescribedby }">
                    <b-form-input id="filterText" type="text" v-model="searchFilter"></b-form-input>
                </b-form-group>
            </b-card>
        
            <b-card title="Bewertung aktualisieren" border-variant="secondary" class="mb-3">
                <b-form-group label="Jahr" label-for="calcYear">
                    <b-form-select v-model="calcYear" text-field="year" value-field="id" :options="years" :state="!$v.calcYear.$error ? null : false"></b-form-select>
                </b-form-group>
                <b-button class="button-margin" variant="primary" @click.prevent="showConfirmation">Bewertung durchführen</b-button>
            </b-card>

        </b-card-group>

        <div class="table-scrollable">
            <b-table striped hover :items="filteredSupplierRisks" :fields="fields" :filter="searchFilter" responsive="sm">
                <template #cell(analysis_supplier_enabled)="data">
                    <div class="d-flex flex-row bd-highlight align-self-center">
                        <b-checkbox v-model="data.item.analysis_supplier_enabled" @change="analysisEnabledForSupplierChange(data.item)" inline class="ml-2 mr-0"></b-checkbox>
                        <div v-if="!data.item.spend" class="ml-3">
                            <b-icon id="spend" icon="exclamation-triangle" scale="2"></b-icon>
                            <b-tooltip target="spend" triggers="hover">kein Einkaufsvolumen gespeichert</b-tooltip>
                        </div>
                    </div>
                </template>
                <template #cell(country_score)="data">
                    <div>
                        <b-badge :to="'/app/user/edit/'+data.item.id" class="p-1 px-2" :variant="!data.item.country_score ? 'light' : data.item.country_score.score === 'Hoch' ? 'danger' : data.item.country_score.score === 'Mittel' ? 'warning' : data.item.country_score.score === 'Niedrig' ? 'success' : 'light'">
                            {{ data.item.country_score ? data.item.country_score.score : 'n/a' }}
                        </b-badge>
                    </div>
                </template>
                <template #cell(sector_score)="data">
                    <div>
                        <b-badge :to="'/app/user/edit/'+data.item.id" class="p-1 px-2" :variant="!data.item.sector_score ? 'light' : data.item.sector_score.score === 'Hoch' ? 'danger' : data.item.sector_score.score === 'Mittel' ? 'warning' : data.item.sector_score.score === 'Niedrig' ? 'success' : 'light'">
                            {{ data.item.sector_score ? data.item.sector_score.score : 'n/a' }}
                        </b-badge>
                    </div>
                </template>
                <template #cell(totalrisk)="data">
                    <div>
                        <b-badge :to="'/app/user/edit/'+data.item.id" class="p-1 px-2" :variant="(!data.item.sector_score || !data.item.country_score) ? 'light' : (data.item.sector_score.score === 'Hoch' || data.item.country_score.score === 'Hoch') ? 'danger'  : (data.item.sector_score.score === 'Mittel' || data.item.country_score.score === 'Mittel') ? 'warning' : (data.item.sector_score.score === 'Niedrig' || data.item.country_score.score === 'Niedrig') ? 'success' : 'light'">
                                 {{ (!data.item.sector_score || !data.item.country_score) ? 'n/a' : (data.item.sector_score.score === 'Hoch' || data.item.country_score.score === 'Hoch') ? 'Hoch'  : (data.item.sector_score.score === 'Mittel' || data.item.country_score.score === 'Mittel') ? 'Mittel' : (data.item.sector_score.score === 'Niedrig' || data.item.country_score.score === 'Niedrig') ? 'Niedrig' : 'no data' }}
                        </b-badge>
                    </div>
                </template>
                <template #cell(spend)="data">
                    <div v-if="!data.item.spend">
                        <b-icon icon="exclamation-triangle" scale="2"></b-icon>
                    </div>
                    <div v-else>
                        {{data.item.spend}}
                    </div>
                </template>
                <template #cell(edit)="row">
                    <b-icon class="mr-2" icon="eye"scale="1.3" v-model="row.detailsShowing" @click="row.toggleDetails"></b-icon>
                    <b-icon class="" icon="pencil-square" scale="1.3" @click="editUser(row.item)"></b-icon>
                </template>
                <template #row-details="row">
                    <b-card-group deck>
                        <b-card title="Adresse">
                            <b-text class="mb-2" v-html="newlineToBr(row.item.address)"></b-text>
                        </b-card>
                        <b-card :title="$t(`user.notes`)">
                            <b-form-group label-for="user-notes">
                                <b-row class="mx-1" id="user-notes" v-html="newlineToBr(row.item.notes)" :rows="5" :max-rows="10"></b-row>
                            </b-form-group>
                        </b-card>
                    </b-card-group>
                </template>
            </b-table>
        </div>
        <b-modal ref="confirmationModal" hide-footer title="Bestätigung">
            <div class="d-block text-center">
                <p>Sie überschreiben alle vorhandenen Bewertungen</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeCalculateAll"><b-icon icon="trash" />Überschreiben</b-button>
        </b-modal>
        <b-modal size="lg" ref="editModal" hide-footer title="Lieferant bearbeiten">
            <div>
                <p>Lieferant: {{ editingUser.name }}</p>
            </div>
            <b-form-group :label="$t(`user.country_name`)" label-for="courtry">
                <vue-multiselect id="country" v-model="editingUser.country" :options="countries" :searchable="true" :close-on-select="true" :show-labels="false" 
                    track-by="id" label="country_name" :allow-empty="false" placeholder="Land auswählen, tippen für Suche">
                    <template slot="singleLabel" slot-scope="{ option }">{{ option.country_name }}</template>
                </vue-multiselect>
            </b-form-group>

            <b-form-group :label="$t(`user.sector_name`)" label-for="sector">
                <vue-multiselect id="sector" v-model="editingUser.sector" :options="sectors" :searchable="true" :close-on-select="true" :show-labels="false" 
                    track-by="id" label="sector_name" :allow-empty="false" placeholder="Branche auswählen, tippen für Suche">
                </vue-multiselect>
            </b-form-group> 

            <b-form-group :label="$t(`user.notes`)" label-for="user-notes">
                <b-form-textarea id="user-notes" v-model="editingUser.notes" :rows="10" :max-rows="10"></b-form-textarea>
            </b-form-group>
            <br />
            <b-row>
                <b-col>
                    <b-button block class="button-margin pl-4 pr-3 py-0" @click="updateSupplier" variant="primary"><b-icon icon="save" />Änderungen Speichern</b-button>
                </b-col>
            </b-row>

        </b-modal>
    </b-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import riskService from '../../../../../services/Riskmanagement/RiskService';
import spendService from '../../../../../services/Riskmanagement/SpendService';
import userService from '../../../../../services/UserService';
import riskAnalysisService from '../../../../../services/Riskmanagement/RiskanalysisService';
import { helpers, required } from 'vuelidate/lib/validators';

export default {
    name: 'supplierrisktab',
    components: {},
    props: [],
    data() {
        return {
            searchFilter: '',
            filterScope: 'unmittelbarer Zulieferer',
            filterYear: [],
            filterCountryScore: [],
            filterSectorScore: [],
            filterCountryEmpty: '0',
            filterSectorEmpty: '0',
            supplierrisks: [],
            calcYear: null,
            score_high: 20,
            score_middle: 10,
            score_low: 5,
            score_max: 50,
            editingUser: { id: null, name: null, sector: null, country: null, notes: null },
        }
    },
    validations: {
        calcYear: {
            required
        },
    },
    created() {
        this.fetchYears();
        this.fetchCountries();
        this.fetchSectors();
        this.fetchSupplierRisks();
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
            fetchCountries: 'countries/fetch',
            fetchSectors: 'sectors/fetch',
        }),
        fetchSupplierRisks() {
            this.$store.commit('loading/setLoading', true);
                riskService.listSupplierRisks()
                    .then(response => {
                        this.supplierrisks = response;
                        this.$store.commit('loading/setLoading', false);
                    }).catch(error => {
                        this.$store.commit('loading/setLoading', false);
                    });
        },
        analysisEnabledForUserChange(item) {
            riskAnalysisService.setEnabledForUser(item.id, item.year.id, item.analysis_enabled)
        },
        analysisEnabledForSupplierChange(item) {
            spendService.setEnabledForSupplier(item.id, item.year.id, item.analysis_supplier_enabled)
                .then({})
                .catch(error => {
                    this.$notify({
                        group: 'global',
                        title: 'Fehler',
                        type: 'error',
                        text: 'Markierung für konkrete Risikoanalyse nicht gespeichert'
                    });
                });
        },
        newlineToBr: function (value) {
                return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        },
        editUser(item) {
            this.editingUser = { id: item.id, name: item.display_name, country: item.country, sector: item.sector, notes: item.notes };
            this.$refs.editModal.show();
        },
        updateSupplier() {
            // todo
            this.$store.commit('loading/setLoading', true);
            const updatePayload = { id: this.editingUser.id, country_id: this.editingUser.country?.id, sector_id: this.editingUser.sector?.id, notes: this.editingUser.notes };
            userService.updateSupplier(updatePayload)
                .then(response => {
                    this.fetchSupplierRisks();
                    this.$refs.editModal.hide();
                    this.$notify({
                        group: 'global',
                        title: 'Lieferant aktualisiert',
                        type: 'success',
                        text: 'Daten gespeichert'
                    });
                    this.$store.commit('loading/setLoading', false);
                })
                .catch(error => {
                    this.$notify({
                        group: 'global',
                        title: 'Fehler',
                        type: 'error',
                        text: 'Daten nicht gespeichert'
                    });
                    this.$store.commit('loading/setLoading', false);
                });
        },
        showConfirmation() {
                this.$v.calcYear.$touch();
                if (this.$v.calcYear.$invalid) {
                    this.$notify({
                        group: 'global',
                        title: 'Eingabe ungültig',
                        type: 'error',
                        text: 'Bewertung nicht durchgeführt'
                    });
                    return;
                }
                this.$refs.confirmationModal.show();
            },
        executeCalculateAll() {
            this.$v.calcYear.$touch();

            if (this.$v.calcYear.$invalid) {
                this.$notify({
                    group: 'global',
                    title: 'Eingabe ungültig',
                    type: 'error',
                    text: 'Bewertung nicht durchgeführt'
                });
                return;
            }

            this.$refs.confirmationModal.hide();
            this.$store.commit('loading/setLoading', true);
            riskService.evaluateAll(this.calcYear)
                .then(response => {
                    this.fetchSupplierRisks();
                    this.$notify({
                        group: 'global',
                        title: 'Bewertung durchgeführt',
                        type: 'success',
                        text: 'Bewertung durchgeführt'
                    });
                    this.$store.commit('loading/setLoading', false);
                })
                .catch(error => {
                    this.$notify({
                        group: 'global',
                        title: 'Fehler',
                        type: 'error',
                        text: 'Bewertung nicht durchgeführt'
                    });
                    this.$store.commit('loading/setLoading', false);
                });
        },
    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
            years: 'years/years',
            countries: 'countries/countries',
            sectors: 'sectors/sectors',
        }),
        filteredSupplierRisks() {
            const result = this.supplierrisks.filter(item => {
                const filterScopeMatch = !this.filterScope || item.lksg_scope === this.filterScope;
                const filterYearMatch = this.filterYear.length === 0 || this.filterYear.includes(item.year.year);
                const filterCountryScoreMatch = this.filterCountryScore.length === 0 || this.filterCountryScore.includes(item.country_score?.score);
                const filterSectorScoreMatch = this.filterSectorScore.length === 0 || this.filterSectorScore.includes(item.sector_score?.score);
                const filterCountryMatch = this.filterCountryEmpty == '0' || item.country == null;
                const filterSectorMatch = this.filterSectorEmpty == '0' || item.sector == null;
                //const filter5Match = this.searchFilter5.length === 0 || (this.searchFilter5.includes("empty") ? (!item.sector_name || item.sector_name === "") : true);
                
                return filterScopeMatch && filterYearMatch && filterCountryScoreMatch && filterSectorScoreMatch && filterCountryMatch && filterSectorMatch;
            });
            return result;
        },

        fields() {
            return [
                { key: 'year.year',                             label: 'Jahr',                                  sortable: true },
                { key: 'edit',                                  label: this.$t(`user.label_actions`)                             },
                { key: 'analysis_supplier_enabled',             label: 'Konkrete Risikoanalyse'                                },
                { key: 'notes',                                 label: 'Bemerkungen'                                           },
                { key: 'supplier_number',                       label: 'Lieferanten Nr.',                       sortable: true },
                { key: 'id',                                    label: 'ID',                                    sortable: true },
                { key: 'display_name',                          label: 'Lieferant',                             sortable: true },
                { key: 'lksg_scope',                            label: 'LkSG-Scope',                            sortable: true },
                { key: 'country.country_iso_3166',              label: 'ISO Code',                              sortable: true },
                { key: 'country.country_name',                  label: 'Land',                                  sortable: true },
                { key: 'country_score',                         label: 'Länderrisiko',                          sortable: true },
                { key: 'sector.sector_code_nace',               label: 'NACE',                                  sortable: true },
                { key: 'sector.sector_name',                    label: 'Branche',                               sortable: true },
                { key: 'sector_score',                          label: 'Branchen-Risiko',                       sortable: true },
                { key: 'spend',                                 label: 'Auftrags-Volumen p.a.',                 sortable: true },
                { key: 'totalrisk',                             label: 'Ergebnis initiale Risiko-Einschätzung', sortable: true },
            ];
        },
    },
}
</script>