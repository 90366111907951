<template>
    <div class="card" style="border-width: 0px" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            {{ $t("risk.header_admin") }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-tabs>
                <b-tab title="Risikoberechnung durchführen">
                    <Calculate />
                </b-tab>
                <b-tab title="Branchenrisiken">
                    <SectorIndicesTab />
                </b-tab>
                <b-tab title="Länderrisiken">
                    <CountryIndicesTab />
                </b-tab>
                <b-tab title="Stammdaten">
                    <Countries ref="countries"></Countries>
                    <Sectors ref="sectors"></Sectors>
                    <Years ref="years"></Years>
                </b-tab>
                <b-tab title="Lieferantenvolumen">
                    <Spends ref="spends"></Spends>
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import Countries from './modules/Countries';
import CountryIndicesTab from './modules/CountryIndicesTab';
import Sectors from './modules/Sectors';
import SectorIndicesTab from './modules/SectorIndicesTab';
import Years from './modules/Years';
import Spends from './modules/Spends';
import Calculate from './modules/Calculate';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'supplychainact',
    components: { Countries, Sectors, Years, Spends, Calculate, SectorIndicesTab, CountryIndicesTab },
    mixins: [dateHelper],
    data() {
        return {
            stickyHeader: true,
        }
    },
    created() {
    },
    methods: {

    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
        }),
    }
};
</script>
