<template>
     <b-card-group deck>
        <b-card class="mb-3 shadow" :title="$t('scdda_admin.calculate.supplier_header')">
            <b-form-group :label="$t('scdda_admin.calculate.supplier_year')" label-for="year1">
                <b-form-select v-model="year1" text-field="year" value-field="id" :options="years" :state="!$v.year1.$error ? null : false"></b-form-select>
            </b-form-group>
            <b-form-group :label="$t('scdda_admin.calculate.supplier_select')" label-for="user1">
                <vue-multiselect id="user1" v-model="user1" :options="suppliers" :searchable="true" :close-on-select="true" :show-labels="false" 
                    track-by="id" label="display_name" :allow-empty="false" :placeholder="$t('scdda_admin.calculate.supplier_select_placeholder')"
                    :class="{ is_invalid: !!$v.user1.$error }">
                </vue-multiselect>
            </b-form-group>
            <b-button class="button-margin" variant="primary" @click.prevent="executeCalculateOne">{{ $t("scdda_admin.calculate.supplier_calculate") }}</b-button>
        </b-card>

        <b-card class="mb-3 shadow" :title="$t('scdda_admin.calculate.year_header')">
            <b-form-group :label="$t('scdda_admin.calculate.year_select')" label-for="year2">
                <b-form-select v-model="year2" text-field="year" value-field="id" :options="years" :state="!$v.year2.$error ? null : false"></b-form-select>
            </b-form-group>
            <b-button class="button-margin" variant="primary" @click.prevent="showConfirmation">{{ $t("scdda_admin.calculate.year_calculate") }}</b-button>
        </b-card>

        <b-modal ref="confirmationModal" hide-footer :title="$t('scdda_admin.calculate.confirmation_header')">
            <div class="d-block text-center">
                <p>{{ $t("scdda_admin.calculate.confirmation_prompt") }}</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeCalculateAll"><b-icon icon="trash" />{{ $t("scdda_admin.calculate.confirmation_button") }}</b-button>
        </b-modal>

    </b-card-group>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import userService from '../../../../../services/UserService';
    import riskService from '../../../../../services/Riskmanagement/RiskService';
    import { helpers, required } from 'vuelidate/lib/validators';

    export default {
        name: 'calculate',
        components: {},
        props: [],
        data() {
            return {
                year1: null,
                user1: null,
                year2: null,
                suppliers: [],
            }
        },
        validations: {
            year1: {
                required
            },
            user1: {
                required
            },
            year2: {
                required
            },
        },
        created() {
            this.$store.commit('loading/setLoading', true);
            return userService.listSuppliers()
                .then(data => {
                    this.suppliers = data.data;
                    this.$store.commit('loading/setLoading', false);
                });
        },
        methods: {
            ...mapActions({
            }),
            executeCalculateOne() {
                this.$v.year1.$touch();
                this.$v.user1.$touch();

                if (this.$v.year1.$invalid || this.$v.user1.$invalid) {
                    this.$notify({
                        group: 'global',
                        title: this.$t("scdda_admin.calculate.check_title"),
                        type: 'error',
                        text: this.$t("scdda_admin.calculate.check_message")
                    });
                    return;
                }

                riskService.evaluateOne(this.year1, this.user1.id)
                    .then(response => {
                        this.$notify({
                            group: 'global',
                            title: this.$t("scdda_admin.calculate.success_title"),
                            type: 'success',
                            text: this.$t("scdda_admin.calculate.success_message")
                        });
                    })
                    .catch(error => {
                        this.$notify({
                            group: 'global',
                            title: this.$t("scdda_admin.calculate.error_title"),
                            type: 'error',
                            text: this.$t("scdda_admin.calculate.error_message")
                        });
                    });
            },
            executeCalculateAll() {
                this.$v.year2.$touch();

                if (this.$v.year2.$invalid) {
                    this.$notify({
                        group: 'global',
                        title: this.$t("scdda_admin.calculate.check_title"),
                        type: 'error',
                        text: this.$t("scdda_admin.calculate.check_message")
                    });
                    return;
                }

                this.$refs.confirmationModal.hide();
                this.$store.commit('loading/setLoading', true);
                riskService.evaluateAll(this.year2)
                    .then(response => {
                        this.$notify({
                            group: 'global',
                            title: this.$t("scdda_admin.calculate.success_title"),
                            type: 'success',
                            text: this.$t("scdda_admin.calculate.success_message")
                        });
                        this.$store.commit('loading/setLoading', false);
                    })
                    .catch(error => {
                        this.$notify({
                            group: 'global',
                            title: this.$t("scdda_admin.calculate.error_title"),
                            type: 'error',
                            text: this.$t("scdda_admin.calculate.error_message")
                        });
                        this.$store.commit('loading/setLoading', false);
                    });
            },
            showConfirmation() {
                this.$v.year2.$touch();
                if (this.$v.year2.$invalid) {
                    this.$notify({
                        group: 'global',
                        title: this.$t("scdda_admin.calculate.check_title"),
                        type: 'error',
                        text: this.$t("scdda_admin.calculate.check_message")
                    });
                    return;
                }
                this.$refs.confirmationModal.show();
            }
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                years: 'years/years'
            }),
        }
    }
</script>