<template>
    <b-card class="mb-3 shadow">
        <b-row>
            <b-col sm="auto"><b-link v-b-toggle:[idtoname(user.id)]><span class="when-open"><b-icon icon="chevron-down" /></span><span class="when-closed"><b-icon icon="chevron-right" /></span></b-link></b-col>
            <b-col v-b-toggle:[idtoname(user.id)]>
                <h4>{{ user.display_name }}</h4>
            </b-col>
            <b-col cols="auto">
                <b-button class="button-margin" type="submit" variant="outline-primary" @click="saveInput" v-if="!modified"><b-icon icon="save" />{{ $t("scdda_evaluation.btn_save") }}</b-button>
                <b-button class="button-margin" type="submit" variant="primary" @click="saveInput" v-else><b-icon icon="save" />{{ $t("scdda_evaluation.btn_savechanges") }}</b-button>
            </b-col>
        </b-row>
        <b-collapse :id="idtoname(user.id)" class="showscroll">
                <b-table striped hover :items="riskanalysis" :fields="fields" responsive="sm">
                    <template #cell(score_1_business)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_1_business" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_2_influence)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_2_influence" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_3_impact)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_3_impact" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_4_probability)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_4_probability" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(score_5_contribution)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_5_contribution" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="heatmap" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(unadjusted_risk_value)="data">
                        {{ getUnadjustedRiskValue(data.item) }}
                    </template>
                    <template #cell(unadjusted_risk_text)="data">
                        {{ getUnadjustedRiskText(data.item) }}
                    </template>
                    <template #cell(countermeasures)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.countermeasures" :placeholder="$t('scdda_evaluation.placeholder_input')" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_1_findings)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.measure_1_findings" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="yesno" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(measure_2_sufficient)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.measure_2_sufficient" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="yesno" value-field="value" text-field="text" @change="setmodified" />
                    </template>
                    <template #cell(measure_3_prportionality)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_3_prportionality" :placeholder="$t('scdda_evaluation.placeholder_input')" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_4_feedback)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_4_feedback" :placeholder="$t('scdda_evaluation.placeholder_input')" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_5_contextual)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_5_contextual" :placeholder="$t('scdda_evaluation.placeholder_input')" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_6_environment)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_6_environment" :placeholder="$t('scdda_evaluation.placeholder_input')" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_7_affected)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_7_affected" :placeholder="$t('scdda_evaluation.placeholder_input')" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(measure_evaluation)="data">
                        <b-form-textarea v-if="data.item.analysis_enabled" v-model="data.item.measure_evaluation" :placeholder="$t('scdda_evaluation.placeholder_input')" style="width: 300px;" @input="setmodified"></b-form-textarea>
                    </template>
                    <template #cell(score_measure_effect)="data">
                        <b-form-select v-if="data.item.analysis_enabled" v-model="data.item.score_measure_effect" :placeholder="$t('scdda_evaluation.placeholder_select')" :options="list_measureeffect" value-field="value" text-field="value" @change="setmodified" />
                    </template>
                    <template #cell(adjusted_risk_value)="data">
                        {{ getAdjustedRiskValue(data.item) }}
                    </template>
                    <template #cell(adjusted_risk_text)="data">
                        {{ getAdjustedRiskText(data.item) }}
                    </template>
                    <template #cell(heatmap_value)="data">
                        {{ getHeatmapValue(data.item) }}
                    </template>

                    <!--
                    <template #cell(question)="data">
                        <span :style="(data.item.relevant == 1) ? 'color: black' : 'color: #aaaaaa'">
                            {{ data.value }}
                        </span>
                    </template>
                    -->
                </b-table>
        </b-collapse>
    </b-card>
</template>
<script>
import riskAnalysisService from '../../../../../../services/Riskmanagement/RiskanalysisService';
export default {
    name: 'analysis-component',
    props: ['user', 'year'],
    data() {
        return {
            loading: true,
            modified: false,
            riskanalysis: [],
            fields: [
                { key: 'questionnaire_title_' + this.$i18n.locale, label: this.$t('scdda_evaluation.col_questionnaire_title'), class: "text-nowrap", stickyColumn: true},
                { key: 'score_1_business', label: this.$t('scdda_evaluation.col_score_1_business') },
                { key: 'score_2_influence', label: this.$t('scdda_evaluation.col_score_2_influence') },
                { key: 'score_3_impact', label: this.$t('scdda_evaluation.col_score_3_impact') },
                { key: 'score_4_probability', label: this.$t('scdda_evaluation.col_score_4_probability') },
                { key: 'score_5_contribution', label: this.$t('scdda_evaluation.col_score_5_contribution') },
                { key: 'unadjusted_risk_value', label: this.$t('scdda_evaluation.col_unadjusted_risk_value') },
                { key: 'unadjusted_risk_text', label: this.$t('scdda_evaluation.col_unadjusted_risk_text'), tdClass: this.tdClassRisk },
                { key: 'countermeasures', label: this.$t('scdda_evaluation.col_countermeasures') },
                { key: 'measure_1_findings', label: this.$t('scdda_evaluation.col_measure_1_findings') },
                { key: 'measure_2_sufficient', label: this.$t('scdda_evaluation.col_measure_2_sufficient') },
                { key: 'measure_3_prportionality', label: this.$t('scdda_evaluation.col_measure_3_prportionality') },
                { key: 'measure_4_feedback', label: this.$t('scdda_evaluation.col_measure_4_feedback') },
                { key: 'measure_5_contextual', label: this.$t('scdda_evaluation.col_measure_5_contextual') },
                { key: 'measure_6_environment', label: this.$t('scdda_evaluation.col_measure_6_environment') },
                { key: 'measure_7_affected', label: this.$t('scdda_evaluation.col_measure_7_affected') },
                { key: 'measure_evaluation', label: this.$t('scdda_evaluation.col_measure_evaluation') },
                { key: 'score_measure_effect', label: this.$t('scdda_evaluation.col_score_measure_effect') },
                { key: 'adjusted_risk_value', label: this.$t('scdda_evaluation.col_adjusted_risk_value') },
                { key: 'adjusted_risk_text', label: this.$t('scdda_evaluation.col_adjusted_risk_text'), tdClass: this.tdClassRisk },
                { key: 'heatmap_value', label: this.$t('scdda_evaluation.col_heatmap_value'), tdClass: this.tdClassHeatmap },
            ],
            heatmap: [
                { value: 1, text: this.$t('scdda_evaluation.heatmap.value_1_very_low') },
                { value: 2, text: this.$t('scdda_evaluation.heatmap.value_2_low') },
                { value: 3, text: this.$t('scdda_evaluation.heatmap.value_3_medium') },
                { value: 4, text: this.$t('scdda_evaluation.heatmap.value_4_high') },
                { value: 5, text: this.$t('scdda_evaluation.heatmap.value_5_very_high') },
            ],
            yesno: [
                { value: true, text: this.$t('scdda_evaluation.option_yes') },
                { value: false, text: this.$t('scdda_evaluation.option_no') },
            ],
            list_measureeffect: [
                {value: 0},
                {value: 1},
                {value: 2},
                {value: 3},
                {value: 4},
                {value: 5},
            ],
        }
    },
    created() {
        this.loading = true;
            this.$store.commit('loading/setLoading', true);
            riskAnalysisService.fetchAnalysis(this.user.id, this.year)
                .then(response => {
                    this.riskanalysis = response;
                    this.$store.commit('loading/setLoading', false);
                    this.loading = false;
                }).catch(error => {
                    this.riskanalysis = [];
                    this.$store.commit('loading/setLoading', false);
                });
    },
    methods: {
        saveInput(bvModalEvent) {
            this.$store.commit('loading/setLoading', true);
            riskAnalysisService.postAnalysis(this.riskanalysis)
                .then(response => {
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t('scdda_evaluation.own.success_title'),
                            type: 'success',
                            text: this.$t('scdda_evaluation.own.success_message')
                        });
                        this.$store.commit('loading/setLoading', false);
                        this.modified = false;
                    }
                    if(!response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t('scdda_evaluation.own.error_title'),
                            type: 'error',
                            text: response.data.message ?? this.$t('scdda_evaluation.own.error_message')
                        });
                        bvModalEvent.preventDefault();
                        this.$store.commit('loading/setLoading', false);
                    }
                })
                .catch(error => {
                this.$notify({
                    group: 'global',
                    title: this.$t('scdda_evaluation.own.error_title'),
                    type: 'error',
                    text: error.message ?? this.$t('scdda_evaluation.own.error_message')
                });
                bvModalEvent.preventDefault();
                this.$store.commit('loading/setLoading', false);
            });
        },
        getUnadjustedRiskValue(item) {
            if (!item.analysis_enabled)
                return null;
            const isOK =
                (!!item.score_1_business)
                && (!!item.score_2_influence)
                && (!!item.score_3_impact)
                && (!!item.score_4_probability)
                && (!!item.score_5_contribution);
            const sum = 
                (item.score_1_business ?? 0)
                + (item.score_2_influence ?? 0)
                + (item.score_3_impact ?? 0)
                + (item.score_4_probability ?? 0)
                + (item.score_5_contribution ?? 0);
            return (isOK) ? Math.round((sum / 5) * 10) / 10 : './.';
            
        },
        getUnadjustedRiskText(item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getUnadjustedRiskValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return null;
            // Exact .5 boundaries don't matter here, 
            // because dividing an integer by 5 won't return a .5 fraction.
            else if (v < 1.5)
                return this.$t('scdda_evaluation.heatmap.value_1_very_low');
            else if (v < 2.5)
                return this.$t('scdda_evaluation.heatmap.value_2_low');
            else if (v < 3.5)
                return this.$t('scdda_evaluation.heatmap.value_3_medium');
            else if (v < 4.5)
                return this.$t('scdda_evaluation.heatmap.value_4_high');
            else
                return this.$t('scdda_evaluation.heatmap.value_5_very_high');
        },
        getAdjustedRiskValue(item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getUnadjustedRiskValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return './.';
            else 
                return Math.round((v - (item.score_measure_effect ?? 0)) * 10) / 10;
        },
        getAdjustedRiskText(item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getAdjustedRiskValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return null;
            // Exact .5 boundaries don't matter here, see above
            else if (v < 1.5)
                return this.$t('scdda_evaluation.heatmap.value_1_very_low');
            else if (v < 2.5)
                return this.$t('scdda_evaluation.heatmap.value_2_low');
            else if (v < 3.5)
                return this.$t('scdda_evaluation.heatmap.value_3_medium');
            else if (v < 4.5)
                return this.$t('scdda_evaluation.heatmap.value_4_high');
            else
                return this.$t('scdda_evaluation.heatmap.value_5_very_high');
        },
        getHeatmapValue(item) {
            if (!item.analysis_enabled)
                return null;
            const product = 
                (item.score_3_impact ?? 0)
                * (item.score_4_probability ?? 0);
            return (product == 0) ? './.' : product;
        },
        tdClassRisk(value, key, item) {
            if (!item.analysis_enabled)
                return null;
            let v = null;
            switch (key) {
                case "unadjusted_risk_text": v = this.getUnadjustedRiskText(item);
                case "adjusted_risk_text": v = this.getAdjustedRiskText(item);
            }
            switch (value) {
                case this.$t('scdda_evaluation.heatmap.value_1_very_low'): return 'bg-sehrgering';
                case this.$t('scdda_evaluation.heatmap.value_2_low'): return 'bg-gering';
                case this.$t('scdda_evaluation.heatmap.value_3_medium'): return 'bg-mittel';
                case this.$t('scdda_evaluation.heatmap.value_4_high'): return 'bg-hoch';
                case this.$t('scdda_evaluation.heatmap.value_5_very_high'): return 'bg-sehrhoch';
            }
            return '';
        },
        tdClassHeatmap(value, key, item) {
            if (!item.analysis_enabled)
                return null;
            const v = this.getHeatmapValue(item);
            if (v == null)
                return null;
            else if (v== './.')
                return null;
            else if (v < 5)
                return 'bg-sehrgering';
            else if (v < 15)
                return 'bg-mittel';

            else
                return 'bg-hoch';
        },
        idtoname(id) {
            return `a${id}`;
        },
        setmodified() {
            this.modified = true;
        },
    },
    computed: {
    }
}
</script>
