<template>
    <b-card :title="$t(`questionnaire.card_list`)" class="mb-3 shadow">
        <b-button v-b-toggle.help_questionnaires pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
        <b-button class="button-margin" variant="primary mb-3" to="/app/questionnaires/new"><b-icon icon="journal-plus" />{{ $t("questionnaire.btn_createquestionnaire") }}</b-button>
        <b-sidebar right id="help_questionnaires" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
            <div class="px-3 py-2">
                <b-list-group>
                    <b-list-group-item class="user-select-all">{{ $t("help.questionnaires_1") }}</b-list-group-item>
                    <b-list-group-item class="user-select-all">{{ $t("help.questionnaires_2") }}</b-list-group-item>
                    <b-list-group-item class="user-select-all">{{ $t("help.questionnaires_3") }}</b-list-group-item>
                    <b-list-group-item class="user-select-all">{{ $t("help.questionnaires_4") }}</b-list-group-item>
                    <b-list-group-item class="user-select-all">{{ $t("help.questionnaires_5") }}</b-list-group-item>
                    <b-list-group-item class="user-select-all">{{ $t("help.questionnaires_6") }}</b-list-group-item>
                    <b-list-group-item class="user-select-all">{{ $t("help.questionnaires_7") }}</b-list-group-item>
                </b-list-group>
            </div>
        </b-sidebar>
        <div class="table-scrollable">
            <b-table striped hover :items="questionnaires" :fields="fields" >
                <template #cell(request)="data">
                    <template v-if="data.item.request == 0">{{ $t(`questionnaire.request_0`) }}</template>
                    <template v-if="data.item.request == 1">{{ $t(`questionnaire.request_1`) }}</template>
                    <template v-if="data.item.request == 2">{{ $t(`questionnaire.request_2`) }}</template>
                    <template v-if="data.item.request == 3">{{ $t(`questionnaire.request_3`) }}</template>
                    <template v-if="data.item.request == 4">{{ $t(`questionnaire.request_4`) }}</template>
                </template>
                <template #cell(status)="data">
                    <template v-if="data.item.status == 0">{{ $t(`questionnaire.status_0`) }}</template>
                    <template v-if="data.item.status == 1">{{ $t(`questionnaire.status_1`) }}</template>
                    <template v-if="data.item.status == 2">{{ $t(`questionnaire.status_2`) }}</template>
                </template>
                <template #cell(actions)="data">
                    <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.id)"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                    <b-link class="m-1" href="#" v-show="data.index < questionnaires.length-1" @click="moveItemDown(data.item.id)"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                    <b-link class="m-1" href="#" :to="'/app/questionnaires/edit/' + data.item.id" v-if="hasRole('Administrator')|hasRole('Berater')|hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                    <b-link class="m-1" href="#" @click.prevent="requestDelete(data.item)" v-if="hasRole('Administrator')|hasRole('Berater')|hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                </template>
            </b-table>
        </div>
        <br />
        <b-button class="button-margin" variant="primary" to="/app/questionnaires/new"><b-icon icon="journal-plus" />{{ $t("questionnaire.btn_createquestionnaire") }}</b-button>
        <b-modal ref="confirmationModal" hide-footer :title="$t(`questionnaire.modal_questionnaire_title`)">
            <div class="d-block text-center">
                <p >{{ $t("questionnaire.modal_questionnaire_delete1") }}<strong>'{{itemToDelete.name}}'</strong>{{ $t("questionnaire.modal_questionnaire_delete2") }}</p>
            </div>
            <b-button class="button-margin mt-3" variant="outline-danger" block @click="deleteQuestionnaire">{{ $t("questionnaire.btn_delete") }}</b-button>
        </b-modal>
    </b-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import QuestionnaireService from '../../../../../services/QuestionnaireService';

export default {
    name: 'questionnaire-list',
    data() {
        return {
            itemToDelete: {},
        }
    },
    created() {
        this.list();
    },
    watch: {
        '$route': 'list'
    },
    methods: {
        ...mapActions({
            list: 'questionnaires/list',
            fetchSelectionLists: 'questionnaires/fetchSelectionLists'
        }),
        requestDelete(item) {
            this.itemToDelete = item;
            this.$refs.confirmationModal.show();
        },
        deleteQuestionnaire() {
            QuestionnaireService.deleteQuestionnaire(this.itemToDelete.id)
                .then(response => {
                    this.$refs.confirmationModal.hide();
                    this.itemToDelete = {};
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestionnaire_title"),
                            type: 'success',
                            text: this.$t("questionnaire.notifiy_deletequestionnaire_message")
                        });

                        this.list();

                    }else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestionnaire_error"),
                            type: 'error',
                            text: response.data.message
                        });
                    }
                });

        },
        moveItemUp(questionnaireId) {
            this.$store.commit('loading/setLoading', true);
            QuestionnaireService.moveQuestionnaireUp(questionnaireId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.list();
                });
        },
        moveItemDown(questionnaireId) {
            this.$store.commit('loading/setLoading', true);
            QuestionnaireService.moveQuestionnaireDown(questionnaireId)
                .then(response => {
                    this.$store.commit('loading/setLoading', false);
                    this.list();
                });
        }
    },
    computed: {
        ...mapGetters({
            questionnaires: 'questionnaires/questionnaires',
            hasRole: 'auth/hasRole',
            usageTypes: 'questionnaires/usageTypes',
        }),
        fields() {
            return [
                {
                    key: 'order',
                    label: this.$t(`questionnaire.label_order`)
                },    
                {
                    key: 'usage_type',
                    label: 'Zuordnung'
                },
                {
                    key: 'year.year',
                    label: this.$t(`questionnaire.label_year`)
                },
                {
                    key: 'name',
                    label: this.$t(`questionnaire.label_name`)
                },
                {
                    key: 'request',
                    label: this.$t(`questionnaire.label_request`)
                },
                {
                    key: 'version',
                    label: this.$t(`questionnaire.label_version`)
                },
                {
                    key: 'status',
                    label: this.$t(`questionnaire.label_status`)
                },
                {
                    key: 'actions',
                    label: this.$t(`questionnaire.label_actions`)
                }
            ];
        },

    }
}
</script>
