import Vue from 'vue';
import Router from 'vue-router';

import AppInit from './guards/AppInit';
import RedirectIfLoggedIn from './guards/RedirectIfLoggedIn';
import IsLoggedIn from './guards/IsLoggedIn';
import IsLoggedInAndNotSupplier from './guards/IsLoggedInAndNotSupplier';
import IsLoggedInAndAcceptedTerms from './guards/IsLoggedInAndAcceptedTerms';

import DashboardComponent from '../components/pages/backend/dashboard/Dashboard';
import ProfileComponent from '../components/pages/backend/profile/Profile';

import Home from '../components/pages/Home';
import Einkauf from '../components/pages/Einkauf';
import Auktionen from '../components/pages/Auktionen';
import Ausschreibungen from '../components/pages/Ausschreibungen';
import Kostenmanagement from '../components/pages/Kostenmanagement';
import Team from '../components/pages/Team';
import Login from '../components/pages/Login';
import ForgotPassword from '../components/pages/ForgotPassword';
import ResetPassword from '../components/pages/Reset';
import UserList from '../components/pages/backend/user/List';
import EditUser from '../components/pages/backend/user/Edit';
import CreateUser from '../components/pages/backend/user/Create';

//import BidAdministration from '../components/pages/backend/administration/BidAdministration';

import RiskAnalysis from '../components/pages/backend/supplychain/riskanalysis.vue';
import CreateCallForBids from '../components/pages/backend/callForBids/Create';
import ViewCallForBids from '../components/pages/backend/callForBids/View';
import ActivityLog from '../components/pages/backend/activitylog/List';
import QuestionnaireList from '../components/pages/backend/questionnaires/List';
import CreateEditQuestionnaire from '../components/pages/backend/questionnaires/Create';
import DownloadQuestionnaireFile from '../components/pages/backend/questionnaires/Download';

import Registration from '../components/pages/Register';
import Activation from '../components/pages/Activation';

import InvitationList from '../components/pages/backend/invitation/List';
import CreateInvitation from '../components/pages/backend/invitation/Create';
//import ContractList from '../components/pages/backend/contracts/List';
//import CreateEditContract from '../components/pages/backend/contracts/Create';
//import ViewContract from '../components/pages/backend/contracts/View';
//import LegalSupport from '../components/pages/Legalsupport';
//import ControllingComponent from '../components/pages/backend/controlling/Controlling';
//import SupplierResearchComponent from '../components/pages/backend/information/Supplierresearch';
import SupplyChainActDirect from '../components/pages/backend/supplychain/direct';
import SupplyChainActIndirect from '../components/pages/backend/supplychain/indirect';
import SupplyChainActEGB from '../components/pages/backend/supplychain/EGBPage';
import SupplyChainAct from '../components/pages/backend/supplychain/risks';

//import ProjectList from '../components/pages/backend/projects/List';
//import CreateEditProject from '../components/pages/backend/projects/Create';

Vue.use(Router);

const router = new Router({
    routes: [
        {
            path: '/',
            component: Home,
        },
        {
            path: '/einkauf',
            component: Einkauf,
        },
        {
            path: '/kostenmanagement',
            component: Kostenmanagement,
        },
        {
            path: '/team',
            component: Team,
        },
        {
            path: '/auktionen',
            component: Auktionen,
        },
        {
            path: '/ausschreibungen',
            component: Ausschreibungen,
        },
        {
            path: '/register/:code',
            component: Registration
        },
        {
            path: '/register/activate/:code',
            component: Activation
        },
        {
            path: '/login',
            component: Login,
            beforeEnter: RedirectIfLoggedIn
        },
        {
            path: '/forgotpassword',
            component: ForgotPassword,
            beforeEnter: RedirectIfLoggedIn
        },
        {
            path: '/reset/:token/:email',
            component: ResetPassword,
            beforeEnter: RedirectIfLoggedIn
        },
        {
            path: '/app/users',
            component: UserList,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/user/edit/:id',
            component: EditUser,
            beforeEnter: IsLoggedIn
        },
        {
            path: '/app/user/new',
            component: CreateUser,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/activitylog',
            component: ActivityLog,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/risikoanalyse',
            component: RiskAnalysis,
            beforeEnter: IsLoggedInAndAcceptedTerms
        },
        {
            path: '/app/campaign/new',
            component: CreateCallForBids,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/campaign/edit/:id',
            component: CreateCallForBids,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/campaign/view/:id',
            component: ViewCallForBids,
            beforeEnter: IsLoggedIn
        },
        {
            path: '/app/questionnaires',
            component: QuestionnaireList,
            beforeEnter: IsLoggedIn
        },
        {
            path: '/app/questionnaires/new',
            component: CreateEditQuestionnaire,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/questionnaires/edit/:id',
            component: CreateEditQuestionnaire,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/questionnaires/download/:downloadKey/:fileName',
            component: DownloadQuestionnaireFile,
            beforeEnter: IsLoggedIn
        },
        {
            path: '/app/profile',
            component: ProfileComponent,
            beforeEnter: IsLoggedIn
        },
        {
            path: '/app/dashboard',
            component: DashboardComponent,
            beforeEnter: IsLoggedIn
        },
        {
            path: '/app/invitations',
            component: InvitationList,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/invitation/edit/:id',
            component: CreateInvitation,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/invitation/new',
            component: CreateInvitation,
            beforeEnter: IsLoggedInAndNotSupplier
        },
        {
            path: '/app/scoring_suppliers',
            component: SupplyChainActDirect,
            beforeEnter: IsLoggedInAndNotSupplier
        },  
        {
            path: '/app/scoring_indirect',
            component: SupplyChainActIndirect,
            beforeEnter: IsLoggedInAndNotSupplier
        },  
        {
            path: '/app/scoring_self',
            component: SupplyChainActEGB,
            beforeEnter: IsLoggedInAndNotSupplier
        },  
        {
            path: '/app/supplychainact',
            component: SupplyChainAct,
            beforeEnter: IsLoggedInAndNotSupplier
        },  
    ]
});

router.beforeEach(AppInit);

export default router;
