<template>
    <div class="card" style="border-width: 0px" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            {{ $t('scdda_evaluation.own.header') }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-tabs>
                <b-tab :title="$t('scdda_evaluation.own.tab_overview')">
                    <EGBRiskTab />
                </b-tab>
                <b-tab :title="$t('scdda_evaluation.own.tab_abstract')">
                    <EGBScoreTab />
                </b-tab>
                <b-tab :title="$t('scdda_evaluation.own.tab_concrete')">
                    <EGBAnalysisTab />
                </b-tab>
                <b-tab :title="$t('scdda_evaluation.own.tab_heatmaps')">
                    <EGBHeatmaps />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import { dateHelper } from '../../../mixins/dateHelper';
import EGBRiskTab from './modules/own/EGBRiskTab';
import EGBScoreTab from './modules/own/EGBScoreTab';
import EGBAnalysisTab from './modules/own/EGBAnalysisTab';
import EGBHeatmaps from './modules/own/EGBHeatmaps';

export default {
    name: 'supplychainact',
    components: { 
        EGBRiskTab,
        EGBScoreTab,
        EGBAnalysisTab,
        EGBHeatmaps,
    },
    mixins: [dateHelper],
    data() {
        return {
            stickyHeader: true,
        }
    },
    created() {
    },
    methods: {

    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
        }),
    }
};
</script>
