
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
require('./bootstrap');
import 'promise-polyfill/src/polyfill';
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue'
import Notifications from 'vue-notification'
import moment from 'moment/min/moment-with-locales'
import VueMoment from 'vue-moment'
import SidebarPlugin from 'bootstrap-vue';
import { IconsPlugin } from 'bootstrap-vue'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import App from './Application'
import router from './router';
import i18n from './i18n';

import store from './store/store';

import axios from 'axios';
import { mapActions } from 'vuex';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead';
import Multiselect from 'vue-multiselect';
import underscore from 'vue-underscore';
import Vuelidate from 'vuelidate'
import fontawesome from '@fortawesome/fontawesome-free';


Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Notifications);
Vue.use(VueMoment, { moment });
Vue.use(SidebarPlugin);
Vue.use(underscore);
Vue.use(Vuelidate);
Vue.use(IconsPlugin);

Vue.component('v-icon', Icon);
Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead);
Vue.component('vue-multiselect', Multiselect);
Vue.component('grafik-component', require('./components/grafik.vue').default);

Vue.filter('formatCurrencyFromInteger', function(value, precision) {
    if(!value) return '';

    if(!precision) {
        precision = 2;
    }

    var val = value !== 0 ? value / 100 : 0;

    return val.toFixed(precision).toString().replace('.', ',');
});

const app = new Vue({
    el: '#app',
    router,
    store,
    i18n,
    template: '<App />',
    components: { App, Icon }
});
