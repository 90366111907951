import axios from 'axios';

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/countryindex/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetch(countryindexId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/countryindex/fetch/' + countryindexId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/countryindex/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    update(countryindexId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/countryindex/update/' + countryindexId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    delete(countryindexId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/risk/countryindex/delete/' + countryindexId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}