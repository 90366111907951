<template>
  <div id="sidebarMenu" class="col-lg-2 col-md-3 d-md-block sidebar collapse scrollbar show">
    <b-row id="sidebar" class="my-3 mx-2">
      <b-button-close @click="smToggle()" class="p-3 d-md-none">x</b-button-close>
      <img src="images/diils_min.png" height="50" class="mx-auto d-flex justify-content-between mt-5 mb-2" alt="">
      <div v-if="!loggedIn">
        <div class="position-sticky  pl-1 pt-1">
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/einkauf" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="building"></b-icon></span> {{ $t("nav.einkauf") }}
              </b-link>
            </li>
            <li class="nav-item">
              <b-link to="/team" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="person-square"></b-icon></span> {{ $t("nav.team") }}
              </b-link>
            </li>
          </ul>
        </div>
        <br />
        <div class="position-sticky pl-1 pt-1">
          <ul class="nav flex-column mb-2">
            <li class="nav-item">
              <b-link to="/login" @click="smToggle" class="nav-link" href="#">
                <span class="nav-link-icon"><b-icon icon="box-arrow-in-right"></b-icon></span> {{ $t("nav.login") }}
              </b-link>
            </li>
            <li class="nav-item"  v-b-toggle.language>
                <a class="nav-link" href="#" id="navbarDropdownLocale" role="button" aria-expanded="false">
                  <span class="nav-link-icon"><b-icon icon="globe2"></b-icon></span> {{ $t("common.localename") }}
                  <b-icon class="ml-auto" icon="chevron-right"></b-icon>
                </a>
                <b-collapse id="language">
                  <template v-for="(lang,code) in supportedLocales">
                    <a class="dropdown-item ml-5" href="#" @click="smToggle" @click.prevent="$i18n.locale=code">{{ lang }}</a>
                  </template>
                </b-collapse>
              </li>
          </ul>
        </div>
      </div>
      <div v-if="loggedIn && currentUser">  
        <div class="position-sticky pl-1 pt-1">
          <div>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-1">
              <span>{{ $t("nav.navbarRiskanalysis") }}</span>
              <div class="col ps-0">
                <hr class="mb-0 navbar-vertical-divider">
              </div>
            </h6>
            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <b-link to="/app/risikoanalyse" @click="smToggle" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="shield-exclamation"></b-icon></span> {{ $t("nav.app_ausschreibungen") }}
                </b-link>
              </li>
              <li class="nav-item">
                <b-link to="/app/invitations" @click="smToggle" v-if="hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="envelope"></b-icon></span> {{ $t("nav.invitations") }}
                </b-link>
              </li>
            </ul>
          </div>

          <div v-if="hasRole('Administrator') || hasRole('Berater') || hasRole('Erweiterter Einkäufer')">
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
              <span>{{ $t("nav.navbarSupplychainact") }}</span>
              <div class="col ps-0">
                <hr class="mb-0 navbar-vertical-divider">
              </div>
            </h6>
            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <b-link to="/app/scoring_self" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="building"></b-icon></span> {{ $t("nav.supplychainact_self") }}
                </b-link>
              </li>
              <li class="nav-item">
                <b-link to="/app/scoring_suppliers" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="building"></b-icon></span> {{ $t("nav.supplychainact_suppliers") }}
                </b-link>
              </li>
              <li class="nav-item">
                <b-link to="/app/scoring_indirect" disabled @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="building"></b-icon></span> {{ $t("nav.supplychainact_indirect") }}
                </b-link>
              </li>
              <li class="nav-item">
                <b-link to="/app/supplychainact" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="tools"></b-icon></span> {{ $t("nav.supplychainact_admin") }}
                </b-link>
              </li>
            </ul>
          </div>

          <div v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')||hasRole('Einkäufer')">
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
              <span>{{ $t("nav.navbarDropdownInternal") }}</span>
              <div class="col ps-0">
                <hr class="mb-0 navbar-vertical-divider">
              </div>
            </h6>
            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <b-link to="/app/users" @click="smToggle" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="person"></b-icon></span> {{ $t("nav.users") }}
                </b-link>
              </li>
              <li class="nav-item">
                <b-link to="/app/activitylog" @click="smToggle" v-if="hasRole('Administrator')||hasRole('Berater')" class="nav-link" href="#">
                  <span class="nav-link-icon"><b-icon icon="clock-history"></b-icon></span> {{ $t("nav.activitylog") }}
                </b-link>
              </li>
            </ul>
          </div>

          <div>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1">
              <span>{{ $t("nav.navbarProfile") }}</span>
              <div class="col ps-0">
                <hr class="mb-0 navbar-vertical-divider">
              </div>
            </h6>
            <ul class="nav flex-column mb-2">
              <li class="nav-item">
                <b-link to="/app/dashboard" @click="smToggle" class="nav-link">
                  <span class="nav-link-icon"><b-icon icon="pie-chart"></b-icon></span> {{ $t("nav.dashboard") }}
                </b-link>
              </li>
              <li class="nav-item">
                <b-link to="/app/profile" @click="smToggle" class="nav-link">
                  <span class="nav-link-icon"><b-icon icon="person-check"></b-icon></span> {{ $t("nav.profile") }}
                </b-link>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#" @click.prevent="logoutCurrentUser">
                  <span class="nav-link-icon"><b-icon icon="box-arrow-right"></b-icon></span> {{ $t("nav.logout") }}
                </a>
              </li>
              <li class="nav-item"  v-b-toggle.language>
                <a class="nav-link" href="#" id="navbarDropdownLocale" role="button" aria-expanded="false">
                  <span class="nav-link-icon"><b-icon icon="globe2"></b-icon></span> {{ $t("common.localename") }}
                  <b-icon class="ml-auto" icon="chevron-right"></b-icon>
                </a>
                <b-collapse id="language">
                  <template v-for="(lang,code) in supportedLocales">
                    <a class="dropdown-item ml-5" href="#" @click="smToggle" @click.prevent="setLocale(code)">{{ lang }}</a>
                  </template>
                </b-collapse>
              </li>
              <li>
                <br />
                <div class="col ps-0">
                  <hr class="mb-0 navbar-vertical-divider">
                </div>
                <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-2 mb-5">{{ loggedInUserEmail }}</h6>
              </li>
            </ul>
          </div>
          <br />
          <br />
        </div>
      </div>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import localeHelper from '../helpers/localeHelper';
export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
        hasRole: 'auth/hasRole',
        loggedIn: 'auth/loggedIn',
        currentUser: 'auth/user',
    }),
    loggedInUserEmail: function() {
        return this.currentUser ? this.currentUser.email : '';
    },
    supportedLocales() {
        return localeHelper.supportedLocales;
    }
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    toggleactive(){
      document.getElementById('sidebarMenu').classList.toggle('active');
      document.getElementById('app').classList.toggle('active');
    },
    smToggle() {
      var screenWidth = window.innerWidth;
      if (screenWidth < 768) {
          document.getElementById("sidebarMenu").classList.toggle('active');
      }
    },
    logoutCurrentUser () {
      this.logout().then(() => {
          return this.$router.push({path:'/login'});
      });
    },
    setLocale(code) {
      this.$i18n.locale=code;
      localStorage.setItem('lang',code)
    },
  }
}
</script>
