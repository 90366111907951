<template>
    <div>
        <b-card class="mb-3 shadow">
            <h3>{{questionnaire['name_' + $i18n.locale]}}</h3>

            <div class="table-scrollable">
                <b-table striped hover :items="questionnaire.questions" :fields="questionFields" sort-by="order">
                    <template #cell()="question">
                        <template v-if="question.field.key ==='question'">
                            {{ question.item['question_' + $i18n.locale] }}
                        </template>
                        <template v-else-if="question.item.type === 1">
                            {{ question.value }}
                        </template>
                        <template v-else-if="question.item.type === 2">
                            {{ question.value }}
                        </template>
                        <template v-else-if="question.item.type === 3">
                            {{ question.value }}
                        </template>
                        <template v-else-if="question.item.type === 4">
                            <b-link v-if="question.value['downloadKey']" @click.prevent="downloadFile(question.value['downloadKey'], question.value['filename'], question.value['mimetype'])">{{ question.value['filename'] }}</b-link>
                        </template>
                        <template v-else-if="question.item.type === 5">
                            {{ question.value[$i18n.locale] }}
                        </template>
                        <template v-else-if="question.item.type === 6">
                            {{ question.value[$i18n.locale] }}
                        </template>
                        <template v-else>
                            {{ question.value }}
                        </template>
                    </template>
                </b-table>
            </div>
        </b-card>
    </div>
</template>
<script>
import questionnaireService from '../../../../services/QuestionnaireService';
import DownloadService from '../../../../services/DownloadService';

export default {
    name: 'auction-questionnaire-answers',
    props:['questionnaire', 'questionFields'],
    created() {
    },
    methods: {
        downloadFile(downloadKey, filename, mimetype) {
            questionnaireService.downloadFile(downloadKey)
                .then(response => {
                    DownloadService.processDownload(response, filename, mimetype);
                });
        }
    }
};
</script>
