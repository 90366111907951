import store from '../../store/store';
import CountryIndexService from '../../services/Riskmanagement/CountryIndexService';

const state = {
    countryindices:  []
};

const getters = {
    countryindices() {
        return state.countryindices;
    }
};

const actions = {
    fetch({commit}) {
        store.dispatch('loading/setLoading', true);
        return CountryIndexService.list()
            .then(data =>{
                commit("setCountryIndices", data.data);
                store.dispatch('loading/setLoading', false);
            });
    },
    delete({commit}, countryindexId) {
        store.dispatch('loading/setLoading', true);
        return CountryIndexService.delete(countryindexId)
            .then(data => {
                store.dispatch("countryindex/fetch");
                store.dispatch('loading/setLoading', false);
            })
    },
    clear({commit}) {
        commit('setCountryindices', []);
    }
}

const mutations = {
    setCountries(state, countries) {    
        state.countryindices = countryindices;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};