<template>
<div class="card" style="border-width: 0px">
    <div class="card-header px-0" style="background-color: #f4f5fa">
        <template v-if="!this.editMode">
            {{ $t("questionnaire.questionnaire_new") }}
        </template>
        <template v-if="this.editMode">
            <router-link :to="'/app/questionnaires'" href="#">{{ $t("questionnaire.questionnaire_edit") }} </router-link>
            <v-icon style="height: 12px" class="mb-1" name="chevron-right" /> {{ questionnaire.name }}
        </template>
    </div>
    <div class="card-body px-0" style="background-color: #f4f5fa">
        <b-form @submit.prevent="onSubmit" v-show="doneLoading">
                <b-tabs>
                <b-tab :title="$t(`questionnaire.tab_basedata`)" active>
                    <b-card :title="$t(`questionnaire.card_basedata`)" class="mb-3 shadow">
                        <b-form-group :label="$t(`questionnaire.name`)" label-for="questionnaire-name">
                            <b-form-input id="questionnaire-name" type="text" required v-model="questionnaire.name" :placeholder="$t(`questionnaire.placeholder_name`)"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.request`)" label-for="questionnaire-request">
                            <b-form-select id="questionnaire-request" :placeholder="$t(`questionnaire.placeholder_request`)" v-model="questionnaire.request">
                                <option value="0">{{ $t("questionnaire.request_0") }}</option>
                                <option value="1">{{ $t("questionnaire.request_1") }}</option>
                                <option value="2">{{ $t("questionnaire.request_2") }}</option>
                                <option value="3">{{ $t("questionnaire.request_3") }}</option>
                                <option value="4">{{ $t("questionnaire.request_4") }}</option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.requesttext`)" label-for="questionnaire-requesttext">
                            <b-form-textarea id="questionnaire-requesttext" type="text" v-model="questionnaire.requesttext" :placeholder="$t(`questionnaire.placeholder_requesttext`)" :rows="4"></b-form-textarea>
                        </b-form-group>
                        <!--
                        <b-form-group :label="$t(`questionnaire.year`)" label-for="questionnaire-year">
                            <b-form-input id="questionnaire-year" type="text" v-model="questionnaire.year" :placeholder="$t(`questionnaire.placeholder_year`)"></b-form-input>
                        </b-form-group>-->
                        <b-form-group :label="$t(`questionnaire.year`)" label-for="questionnaire-year">
                            <vue-multiselect id="questionnaire-year" v-model="questionnaire.year" :options="years" :searchable="false" :close-on-select="true" :show-labels="false" 
                                track-by="id" label="year" :allow-empty="false" :placeholder="$t(`questionnaire.placeholder_year`)">
                                <template slot="singleLabel" slot-scope="{ option }">{{ option.year }}</template>
                            </vue-multiselect>
                        </b-form-group>

                        <b-form-group label="Zuordnung zum Aktionstyp" label-for="usage-type">
                            <b-form-select text-field="usage_type" value-field="id" :options="usageTypes" v-model="questionnaire.usage_type_id" ></b-form-select>
                        </b-form-group>

                        <b-form-group :label="$t(`questionnaire.version`)" label-for="questionnaire-version">
                            <b-form-input id="questionnaire-version" type="text" v-model="questionnaire.version" :placeholder="$t(`questionnaire.placeholder_version`)"></b-form-input>
                        </b-form-group>
                        <b-form-group :label="$t(`questionnaire.status`)" label-for="questionnaire-status">
                            <b-form-select id="questionnaire-status" :placeholder="$t(`questionnaire.placeholder_status`)" v-model="questionnaire.status">
                                <option value="0">{{ $t("questionnaire.status_0") }}</option>
                                <option value="1">{{ $t("questionnaire.status_1") }}</option>
                                <option value="2">{{ $t("questionnaire.status_2") }}</option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-checkbox v-model="questionnaire.relevant_for_analysis">{{  $t("questionnaire.relevant_for_analysis") }}</b-form-checkbox>
                    </b-card>
                </b-tab>

                <b-tab :title="$t(`questionnaire.tab_questions`)" :disabled="!editMode">
                    <b-card :title="$t(`questionnaire.card_questions`)" class="mb-3 shadow"> 
                        <b-button class="button-margin" variant="primary" @click="newQuestion"><b-icon icon="question-square" scale="1.2"></b-icon> {{ $t("questionnaire.btn_createquestion") }}</b-button>
                        <div class="table-scrollable">
                            <br/>
                            <b-table striped hover :items="questions" :fields="questionFields" sort-by="order">
                                <template #cell(required)="data">
                                    <template v-if="data.item.required">
                                        {{ $t("questionnaire.option_yes") }}
                                    </template>
                                    <template v-if="!data.item.required">
                                        {{ $t("questionnaire.option_no") }}
                                    </template>
                                </template>
                                <template #cell(type)="data">
                                    <span v-if="data.item.type == 1">
                                        {{ $t("questionnaire.option_integer") }}
                                    </span>
                                    <span v-if="data.item.type == 2">
                                        {{ $t("questionnaire.option_double") }}
                                    </span>
                                    <span v-if="data.item.type == 3">
                                        {{ $t("questionnaire.option_text") }}
                                    </span>
                                    <span v-if="data.item.type == 4">
                                        {{ $t("questionnaire.option_file") }}
                                    </span>
                                    <span v-if="data.item.type == 5">
                                        {{ $t("questionnaire.option_options") }}
                                    </span>
                                    <span v-if="data.item.type == 6">
                                        {{ $t("questionnaire.option_checkboxes") }}
                                    </span>
                                    <span v-if="data.item.type == 7">
                                        {{ $t("questionnaire.option_endofquestionnaire") }}
                                    </span>
                                </template>
                                <template #cell(check)="data">
                                    <span v-if="data.item.selection != ''">
                                        {{ $t("questionnaire.option_list") }}
                                    </span>
                                    <span v-if="data.item.check_regex != ''">
                                        {{ $t("questionnaire.option_regex") }}
                                    </span>
                                    <span v-if="data.item.check_eval != ''">
                                        {{ $t("questionnaire.option_eval") }}
                                    </span>
                                </template>
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" v-show="data.index > 0" @click="moveItemUp(data.item.order)"><b-icon icon="chevron-up" scale="1.2"></b-icon></b-link>
                                    <b-link class="m-1" href="#" v-show="data.index < questions.length-1" @click="moveItemDown(data.item.order)"><b-icon icon="chevron-down" scale="1.2"></b-icon></b-link>
                                    <b-link class="m-1" href="#" @click.prevent="editQuestion(data.item)"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                                    <b-link class="m-1" href="#" @click.prevent="requestDelete(data.item)"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                                </template>
                            </b-table>
                        </div>
                        <br/>
                        <b-button class="button-margin" variant="primary" @click="newQuestion"><b-icon icon="question-square" scale="1.2"></b-icon> {{ $t("questionnaire.btn_createquestion") }}</b-button>
                    </b-card>
                </b-tab>

                <b-tab :title="$t(`questionnaire.tab_usage`)" :disabled="!editMode">
                    <b-card :title="$t(`questionnaire.auctions`)" class="mb-3 shadow"> 
                        <div class="table-scrollable">
                            <b-table striped hover :items="questionnaire.related_auctions" :fields="relatedAuctionsTableFields">
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                        {{ data.item.organizer.name }} - {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">-</template>
                                </template>
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" :to="'/app/auktionen/view/' + data.item.id "><b-icon icon="eye" scale="1.2"></b-icon></b-link>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                    
                    <b-card :title="$t(`questionnaire.callforbids`)" class="mb-3 shadow"> 
                        <div class="table-scrollable">
                            <b-table striped hover :items="questionnaire.related_callforbids" :fields="relatedCallForBidsTableFields">
                                <template #cell(start_date)="data">
                                    {{ data.item.start_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(end_date)="data">
                                    {{ data.item.end_date | moment("DD.MM.YYYY HH:mm")}}
                                </template>
                                <template #cell(organizer)="data">
                                    <template v-if="data.item.organizer">
                                        {{ data.item.organizer.name }} - {{data.item.organizer.company}}
                                    </template>
                                    <template v-if="!data.item.organizer">-</template>
                                </template>
                                <template #cell(actions)="data">
                                    <b-link class="m-1" href="#" :to="'/app/ausschreibung/view/' + data.item.id "><b-icon icon="eye" scale="1.2"></b-icon></b-link>
                                </template>
                                <template #cell(status)="data">
                                    {{getAuctionStatus(data.item.start_date, data.item.end_date)}}
                                </template>
                            </b-table>
                        </div>
                    </b-card>
                </b-tab>
                <b-tab :title="$t(`questionnaire.tab_preview`)" :disabled="!editMode">
                    <b-card class="mb-3 shadow">
                        <b-alert show v-if="questionnaire.requesttext" variant="info">
                            <b-form-group v-if="questionnaire.requesttext" label-for="questionnaire-requesttext">
                                <b-row class="mr-1" v-html="newlineToBr(questionnaire.requesttext)" id="questionnaire-requesttext"></b-row>
                            </b-form-group>
                        </b-alert>
                        <h3>{{questionnaire.name}}</h3>
                        <b-form @submit.prevent="submitForm()">
                            <template v-for="question in questionnaire.questions">
                                <b-form-group v-bind:key="question.id" >
                                    <label v-if="question.type !== 7">
                                        <span v-html="newlineToBr(question.questiontext)"></span>{{ question.required ? '*' : '' }} <span><sup>{{ question.id }}</sup></span>
                                        <b-icon v-b-modal="'collapse-' + question.id" v-if="question.question_note !== '' && question.question_note !== null" style="cursor: pointer;" icon="info-circle" shift-v="2" font-scale="1.2" variant="primary"></b-icon>
                                        <b-modal :id="'collapse-' + question.id" centered ok-only>
                                            <p class="mt-1 text-muted" style="overflow-wrap: break-word;">{{question.question_note}}</p>
                                        </b-modal>
                                    </label>
                                    <label v-if="question.type === 7" class="w-100 my-3">
                                        <b-alert show variant="success" class="p-5">
                                            <span v-html="newlineToBr(question.questiontext)"></span>
                                            <b-icon v-b-modal="'collapse-' + question.id" v-if="question.question_note !== '' && question.question_note !== null" style="cursor: pointer;" icon="info-circle"></b-icon>
                                        </b-alert>
                                        <b-modal :id="'collapse-' + question.id" centered>
                                            <p class="mt-1 text-muted" style="overflow-wrap: break-word;">{{question.question_note}}</p>
                                        </b-modal>
                                    </label>
                                    <template v-if="question.type === 1">
                                        <b-form-input type="number" :name="'question-'+question.id" />
                                    </template>
                                    <template v-if="question.type === 2">
                                        <b-form-input type="number" :name="'question-'+question.id" />
                                    </template>
                                    <template v-if="question.type === 3 && (question.selection === null || question.selection === '')">
                                        <b-form-input type="text" :name="'question-'+question.id" />
                                    </template>
                                    <template v-if="question.type === 3 && (question.selection !== null && question.selection !== '')">
                                        <b-form-select :name="'question-'+question.id">
                                            <template v-for="value in question.selection.split(';')">
                                                <option v-bind:key="'question-'+question.id+'-'+value" :value="value">{{value}}</option>
                                            </template>
                                        </b-form-select>
                                    </template>
                                    <template v-if="question.type === 4">
                                        <b-form-file :name="'question-'+question.id" placeholder="Datei wählen..."></b-form-file>
                                    </template>
                                    <template v-if="question.type === 5 && question.answeroptions.length != 0">
                                        <b-form-radio-group class="ml-3" stacked :name="'question-'+question.id">
                                            <template v-for="value in question.answeroptions">
                                                <b-form-radio :value="value.id">{{ value.answer_text }}</b-form-radio>
                                            </template>
                                        </b-form-radio-group>
                                    </template>
                                    <template v-if="question.type === 6 && question.answeroptions.length != 0">
                                        <b-form-checkbox-group class="ml-3" stacked :name="'question-'+question.id">
                                            <template v-for="value in question.answeroptions">
                                                <b-form-checkbox :value="value.id">{{ value.answer_text }}</b-form-checkbox>
                                            </template>
                                        </b-form-checkbox-group>
                                    </template>
                                </b-form-group>
                            </template>
                            <b-button class="button-margin mt-4" disabled type="submit" variant="primary"><b-icon icon="save" />{{ $t("questionnaire.btn_save") }}</b-button>
                            <b-button class="button-margin mt-4" disabled v-if="(questionnaire.request == 0 || questionnaire.request == 1)"><b-icon icon="download" />{{ $t("questionnaire.btn_download") }}</b-button>
                        </b-form>
                    </b-card>
                    <br />
                </b-tab>
                <b-tab title="Länder">
                    <b-card title="Relevante Länder zur Risikoanalyse" class="mb-3 shadow"> 
                        <b-container>
                            <b-row class="d-flex" cols="2" cols-lg="4" cols-xl="6">
                                <b-col v-for="country in relatedCountries" :key="country.id">
                                    <b-checkbox v-model="country.related">{{ country.country_name }}</b-checkbox>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-card>
                </b-tab>
                <b-tab title="Branchen">
                    <b-card title="Relevante Branchen zur Risikoanalyse" class="mb-3 shadow"> 
                        <b-container style="column-count: 2; column-gap: 1.5">
                            <b-row>
                                <b-col cols="12" v-for="sector in sortedRelatedSectors" :key="sector.id">
                                    <b-checkbox v-model="sector.related">{{ sector.sector_code_nace }}: {{ sector.sector_name }}</b-checkbox>
                                </b-col>
                            </b-row>
                        </b-container>
                    </b-card>
                </b-tab>
            </b-tabs>
            <br />
            <b-button class="button-margin" type="submit" variant="primary"><b-icon icon="check" scale="1.2"></b-icon> {{ $t("questionnaire.btn_save") }}</b-button>
        </b-form>
    </div>
    <b-modal ref="questionDialog" hide-footer size="lg" :title="$t(`questionnaire.modal_title`)">
        <b-container>
            <b-form @submit.prevent="onQuestionFormSubmit">
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_category`)" label-for="question-name">
                            <b-form-input id="question-name" v-model="currentQuestion.name" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_question`)" label-for="question-question">
                            <b-form-textarea id="question-question" v-model="currentQuestion.questiontext" class="overflow-auto mb-3" :no-resize="false" :rows="5"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_question_note`)" label-for="question-question-note">
                            <b-form-textarea id="question-question-note" v-model="currentQuestion.question_note" class="overflow-auto mb-3" :no-resize="false" :rows="5"></b-form-textarea>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_questionrequired`)" label-for="question-required">
                            <b-form-checkbox  id="question-required" v-model="currentQuestion.required" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_type`)" label-for="question-type">
                            <b-form-select id="question-type" v-model="currentQuestion.type" :options="questionTypes" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <Answeroptions :items="currentQuestion.answeroptions" :questionId="currentQuestion.id" />
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_regex`)" label-for="question-checkregex">
                            <b-button v-b-toggle.help_regex pill size="sm" variant="outline-secondary" style="font-family: times; height: 1.3rem; width: 1.3rem" class="d-flex pl-2 p-0 ml-auto"><em>i</em></b-button>
                        </b-form-group>
                        <b-form-input id="question-checkregex" v-model="currentQuestion.check_regex" />
                        <b-sidebar right id="help_regex" :title="$t(`help.title_regex`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                            <div class="px-3 py-2">
                                <b-list-group>
                                    <b-list-group-item button class="d-flex align-items-center"><span v-html="$t('help.regex_1')" /><b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.regex_code_1`))"></b-icon></b-list-group-item>
                                    <b-list-group-item button class="d-flex align-items-center"><span v-html="$t('help.regex_2')" /><b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.regex_code_2`))"></b-icon></b-list-group-item>
                                    <b-list-group-item button class="d-flex align-items-center"><span v-html="$t('help.regex_3')" /><b-icon class="ml-auto" icon="clipboard" @click="copyText($t(`help.regex_code_3`))"></b-icon></b-list-group-item>
                                </b-list-group>
                            </div>
                        </b-sidebar>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-form-group :label="$t(`questionnaire.label_eval`)" label-for="question-eval">
                            <b-form-input id="question-eval" v-model="currentQuestion.check_eval" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col>
                        <b-button class="button-margin" type="submit"><b-icon icon="check" scale="1.2"></b-icon> {{ $t("questionnaire.btn_save") }} </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </b-container>
    </b-modal>
    <b-modal ref="confirmationModal" hide-footer :title="$t(`questionnaire.modal_question_delete`)">
        <div class="d-block text-center">
            <p >{{ $t("questionnaire.modal_note1") }}<strong>'{{itemToDelete.name}}'</strong>{{ $t("questionnaire.modal_note2") }}</p>
        </div>
        <b-button class="button-margin mt-3" variant="outline-danger" block @click.prevent="deleteQuestion">Löschen</b-button>
    </b-modal>
</div>
</template>
<script>
import {mapActions, mapGetters} from 'vuex';
import QuestionnaireService from '../../../../services/QuestionnaireService';
import Answeroptions from './modules/Answeroptions';

export default {
    name: 'questionnaire-createedit',
    components: {Answeroptions},
    data() {
        return {
            questionnaireId: 0,
            itemToDelete:{},
            editMode: false,
            doneLoading: false,
            questionnaire: {},
            questions: [],
            assignedAuctions: [],
            relatedCountries: [],
            relatedSectors: [],
            usageTypes: [],
            currentQuestion: {
                answeroptions: [],
            },
            currentQuestionEditMode: false,
            questionTypes: [
                {   value: 1,   text: this.$t(`questionnaire.option_integer`)               },
                {   value: 2,   text: this.$t(`questionnaire.option_double`)                },
                {   value: 3,   text: this.$t(`questionnaire.option_text`)                  },
                {   value: 4,   text: this.$t(`questionnaire.option_file`)                  },
                {   value: 5,   text: this.$t(`questionnaire.option_options`)               },
                {   value: 6,   text: this.$t(`questionnaire.option_checkboxes`)            },
                {   value: 7,   text: this.$t(`questionnaire.option_endofquestionnaire`)    }
            ],
            score_high: 20,
            score_middle: 40,
            score_low: 60,
        }
    },
    created() {
        this.$store.commit('loading/setLoading', true);
        return this.fetchYears()
        .then(() => { 
            return QuestionnaireService.fetchSelectionLists()
            .then(response => {
                this.usageTypes = response.data.usageTypes;
                this.relatedCountries = response.data.countries;
                this.relatedSectors = response.data.sectors;

                if(this.$route.params.id) {
                    this.editMode = true;
                    this.$store.commit('loading/setLoading', true);
                    this.questionnaireId = this.$route.params.id;
                    QuestionnaireService.fetch(this.questionnaireId)
                        .then(response => {
                            this.doneLoading = true;
                            this.questionnaire = response.data;
                            this.markRelatedItems();

                            return this.fetchQuestions(this.questionnaireId)
                                .then(() => {
                                    return QuestionnaireService.fetchAssignedAuctions(this.questionnaireId)
                                        .then(response => {
                                            this.assignedAuctions = response.data;
                                            this.$store.commit('loading/setLoading', false);
                                        })
                                });
                        })
                }else {
                    this.$store.commit('loading/setLoading', false);
                    this.doneLoading = true;
                }
            });
        });
    },
    methods: {
        ...mapActions({
            fetchYears: 'years/fetch',
        }),
        onSubmit() {
            this.$store.commit('loading/setLoading', true);
            if (this.questionnaire.year) {
                this.questionnaire.year_id = this.questionnaire.year.id;
            } else {
                this.questionnaire.year_id = null;
            }

            if(!this.editMode) {
                QuestionnaireService.create(this.questionnaire.name)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(response.success === undefined) {
                            this.questionnaire = response.data;
                            this.editMode = true;

                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notify_success_title"),
                                type: 'success',
                                text: this.$t("questionnaire.notify_success_message")
                            });
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notify_error_title"),
                                type: 'error',
                                text: response.message
                            });
                        }
                    });
            }else {
                this.questionnaire.questions = this.questions;
                this.questionnaire.selectedCountries = this.relatedCountries.filter((item)=>{return item.related;}).map((item)=>{return item.id;});
                this.questionnaire.selectedSectors = this.relatedSectors.filter((item)=>{return item.related;}).map((item)=>{return item.id;});
                QuestionnaireService.update(this.questionnaire)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(response.success === undefined) {
                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notifiy_update_title"),
                                type: 'success',
                                text: this.$t("questionnaire.notifiy_update_message")
                            });
                            return this.fetchQuestions();
                        }else {
                            this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notifiy_update_error"),
                                type: 'error',
                                text: response.message
                            });
                        }
                    });
            }
        },
        newQuestion() {
            this.currentQuestion = {};
            this.currentQuestionEditMode = false;
            this.$refs.questionDialog.show();
        },
        editQuestion(question) {
            this.currentQuestion = JSON.parse(JSON.stringify(question));
            this.currentQuestionEditMode = true;
            this.$refs.questionDialog.show();
        },
        onQuestionFormSubmit() {
            if(!this.currentQuestionEditMode) {
                QuestionnaireService.createQuestion(this.currentQuestion, this.questionnaire.id)
                    .then(response => {
                        this.$refs.questionDialog.hide();
                        this.currentQuestion = {};
                        return QuestionnaireService.fetchQuestions(this.questionnaire.id)
                            .then(questionsResponse => {
                                this.questions = questionsResponse.data;
                            });
                    });
            }else {
                QuestionnaireService.updateQuestion(this.currentQuestion)
                    .then(response => {
                        this.$refs.questionDialog.hide();
                        this.currentQuestion = {};
                        this.currentQuestionEditMode = false;
                        this.$notify({
                                group: 'global',
                                title: this.$t("questionnaire.notifiy_updatequestion_title"),
                                type: 'success',
                                text: this.$t("questionnaire.notifiy_updatequestion_message")
                            });
                        this.$store.commit('loading/setLoading', true);
                        return this.fetchQuestions()
                            .then(() => {
                                this.$store.commit('loading/setLoading', false);
                            });
                    })
            }
            this.reload();
        },
        findItemByOrder(order) {
            for(var i=0; i< this.questions.length; i++) {
                if(this.questions[i].order == order) {
                    return this.questions[i];
                }
            }
        },
        moveItemUp(order) {
            var itemToMoveUp = this.findItemByOrder(order);
            var itemToMoveDown = this.findItemByOrder(order-1);

            itemToMoveUp.order = order-1;
            itemToMoveDown.order = order
        },
        moveItemDown(order) {
            var itemToMoveDown = this.findItemByOrder(order);
            var itemToMoveUp = this.findItemByOrder(order+1);

            itemToMoveDown.order = order+1
            itemToMoveUp.order = order;
        },
        fetchQuestions() {
            return QuestionnaireService.fetchQuestions(this.questionnaire.id)
                .then(response => {
                    this.questions = response.data;
                });
        },
        requestDelete(item) {
            this.itemToDelete = item;
            this.$refs.confirmationModal.show();
        },
        deleteQuestion() {
            QuestionnaireService.deleteQuestion(this.questionnaire.id,this.itemToDelete.id)
                .then(response => {
                    this.$refs.confirmationModal.hide();
                    this.itemToDelete = {};
                    if(response.data.success) {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestion_title"),
                            type: 'success',
                            text: this.$t("questionnaire.notifiy_deletequestion_message")
                        });

                        return this.fetchQuestions();

                    }else {
                        this.$notify({
                            group: 'global',
                            title: this.$t("questionnaire.notifiy_deletequestion_error"),
                            type: 'error',
                            text: this.$t("questionnaire.notifiy_deletequestion_errormessage")
                        });
                    }
                })
                .catch(error => {
                    this.$notify({
                        group: 'global',
                        title: this.$t("questionnaire.notifiy_deletequestion_error"),
                        type: 'error',
                        text: this.$t("questionnaire.notifiy_deletequestion_errormessage")
                    });
                });
        },
        showQuestion(id) {
            let isConditional = false;
            let conditionFulfilled = false;
            this.questionnaire.questions.forEach(question => {
                switch(question.type)
                {
                    case 2:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                // Überprüfen, ob der Inhalt der Antwort nicht leer ist
                                if (this.answers[question.id]) {
                                    conditionFulfilled = true; // Zeige die Frage, wenn der Inhalt nicht leer ist
                                }
                            }
                        });
                        break;
                    case 3:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                // Überprüfen, ob der Inhalt der Antwort nicht leer ist
                                if (this.answers[question.id] && (this.answers[question.id].trim() !== '')) {
                                    conditionFulfilled = true; // Zeige die Frage, wenn der Inhalt nicht leer ist
                                }
                            }
                        });
                        break;
                    case 5:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                conditionFulfilled = conditionFulfilled ||  (this.answers[question.id] == answeroption.id);
                            }
                        });
                        break;
                    case 6:
                        question.answeroptions.forEach(answeroption => {
                            if (answeroption.conditional_question_id == id) {
                                isConditional = true;
                                if (this.answers[question.id]) 
                                {
                                    conditionFulfilled = conditionFulfilled || (this.answers[question.id].includes(answeroption.id));
                                }
                            }
                        });
                        break;
                    default:
                        break;
                }
            });
            return (!isConditional) || conditionFulfilled;
        },
        newlineToBr: function (value) {
            return value.replace(/(?:\r\n|\r|\n)/g, '<br />');
        },
        reload: function () {
            this.$store.commit('loading/setLoading', true);
            QuestionnaireService.fetch(this.questionnaireId)
                .then(response => {
                    this.doneLoading = true;
                    this.questionnaire = response.data;
                    this.markRelatedItems();

                    return this.fetchQuestions(this.questionnaireId)
                        .then(() => {
                            return QuestionnaireService.fetchAssignedAuctions(this.questionnaireId)
                                .then(response => {
                                    this.assignedAuctions = response.data;
                                    this.$store.commit('loading/setLoading', false);
                                })
                        });
                })
        },
        markRelatedItems: function () {
            this.questionnaire.related_countries.forEach((country) => {
                const found = this.relatedCountries.find((item) => { return item.id == country.id });
                if (!!found) {
                    found.related = true;
                }
            }, this);
            this.questionnaire.related_sectors.forEach((sector) => {
                const found = this.relatedSectors.find((item) => { return item.id == sector.id });
                if (!!found) {
                    found.related = true;
                }
            }, this);
        }
    },
    computed: {
        ...mapGetters({
            years: 'years/years',
        }),
        sortedRelatedSectors() {
            return this.relatedSectors.sort((a, b) => {
                // Sortiere nach sector_code_nace in absteigender Reihenfolge
                if (a.sector_code_nace > b.sector_code_nace) return 1;
                if (a.sector_code_nace < b.sector_code_nace) return -1;
                return 0;
            });
        },
        questionFields() {
            return [
                { key: 'actions',                           label: this.$t(`questionnaire.label_actions`)  	        },
                { key: 'id',                                label: 'Id' 	                                        },
                { key: 'name',                              label: this.$t(`questionnaire.label_category`)    	    },
                { key: 'questiontext',                      label: this.$t(`questionnaire.label_question`)    	    },
                { key: 'question_note',                     label: this.$t(`questionnaire.label_question_note`)  	},
                { key: 'required',                          label: this.$t(`questionnaire.label_questionrequired`)  },
                { key: 'type',                              label: this.$t(`questionnaire.label_type`)    	        },
                { key: 'score_value',                       label: 'Fragebogen Score',      sortable: true          },
                { key: 'answer_score',                      label: 'Punkte '                                        },
                { key: 'check',                             label: this.$t(`questionnaire.label_check`)  	        }
            ];
        },
        relatedAuctionsTableFields() {
            return [
                { key: 'title',                             label: this.$t(`auction_list.auction.column_title`),       sortable: true },
                { key: 'organizer',                         label: this.$t(`auction_list.auction.column_organizer`),   sortable: true },
                { key: 'start_date',                        label: this.$t(`auction_list.auction.column_start`),       sortable: true },
                { key: 'end_date',                          label: this.$t(`auction_list.auction.column_end`),         sortable: true },
                { key: 'actions',                           label: this.$t(`auction_list.auction.column_actions`)                     }
            ];
        },
        relatedCallForBidsTableFields() {
            return [
                {key: 'title',                              label: this.$t(`auction_list.tender.column_title`),         sortable: true },
                {key: 'organizer',                          label: this.$t(`auction_list.tender.column_organizer`),     sortable: true },
                {key: 'start_date',                         label: this.$t(`auction_list.tender.column_start`),         sortable: true },
                {key: 'end_date',                           label: this.$t(`auction_list.tender.column_end`),           sortable: true },
                {key: 'actions',                            label: this.$t(`auction_list.tender.column_actions`)                       }
            ];
        },
    }
};
</script>
    