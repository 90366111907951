<template>
    <div class="card" style="border-width: 0px" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')">
        <div class="card-header px-0" style="background-color: #f4f5fa">
            {{ $t('scdda_evaluation.indirect.header') }}
        </div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-tabs>
                <b-tab :title="$t('scdda_evaluation.indirect.tab_overview')" active>
                    <SupplierRiskTab />
                </b-tab>
                <b-tab :title="$t('scdda_evaluation.indirect.tab_abstract')">
                    <SupplierRiskTab2 />
                </b-tab>
                <b-tab :title="$t('scdda_evaluation.indirect.tab_specific')">
                    <SupplierAnalysisTab />
                </b-tab>
                <b-tab :title="$t('scdda_evaluation.indirect.tab_heatmaps')">
                    <SupplierHeatmaps />
                </b-tab>
            </b-tabs>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import SupplierRiskTab from './modules/indirect/SupplierRiskTab';
import SupplierRiskTab2 from './modules/indirect/SupplierRiskTab2';
import SupplierAnalysisTab from './modules/indirect/SupplierAnalysisTab';
import SupplierHeatmaps from './modules/indirect/SupplierHeatmaps';
import { dateHelper } from '../../../mixins/dateHelper';

export default {
    name: 'supplychainact',
    components: { 
        SupplierRiskTab, 
        SupplierRiskTab2, 
        SupplierAnalysisTab,
        SupplierHeatmaps,
    },
    mixins: [dateHelper],
    data() {
        return {
            stickyHeader: true,
        }
    },
    created() {
    },
    methods: {

    },
    computed: {
        ...mapGetters({
            hasRole: 'auth/hasRole',
        }),
    }
};
</script>
