<template>
    <div>
        <b-card class="mb-3 shadow" :title="$t('scdda_admin.years.header')">
            <b-button class="button-margin" variant="primary" @click.prevent="showNewItemForm" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="folder-plus" />{{ $t("scdda_admin.years.btn_new") }}</b-button>

            <br />
            <b-form-group horizontal :label="$t('scdda_admin.years.search')" class="mb-0">
                <b-input-group>
                    <b-input v-model="searchFilter" :placeholder="$t('scdda_admin.years.search_placeholder')" />
                    <b-input-group-append>
                        <b-button :disabled="!searchFilter" @click="searchFilter = ''">{{ $t("scdda_admin.years.search_clear") }}</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form-group>
            <br />
            <div class="table-scrollable">
            <b-table striped hover :items="years" :fields="fields" :filter="searchFilter">
                <template #cell(actions)="data">
                    <b-link class="m-1" href="#" @click="editItem(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="pencil-square" scale="1.2"></b-icon></b-link>
                    <b-link class="m-1" href="#" @click="requestConfirmation(data.item)" v-if="hasRole('Administrator')||hasRole('Berater')||hasRole('Erweiterter Einkäufer')"><b-icon icon="trash" scale="1.2"></b-icon></b-link>
                </template>
            </b-table>
            </div>
            <br />
            <b-modal ref="confirmationModal" hide-footer :title="$t('scdda_admin.years.confirmation_header')">
                <div class="d-block text-center">
                    <p v-if="itemContext">{{ $t('scdda_admin.years.confirmation_message', {title: itemContext.year}) }}</p>
                </div>
                <b-button class="button-margin mt-3" variant="outline-danger" block @click="executeDelete"><b-icon icon="trash" />{{ $t("scdda_admin.years.confirmation_button") }}</b-button>
            </b-modal>
        </b-card>
        <Edityear ref="Edityear" :savedCallback="reload" />
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex';
    import Edityear from './Edityear';
    import YearService from '../../../../../services/Riskmanagement/YearService';

    export default {
        name: 'years',
        components: {Edityear},
        props: [],
        data() {
            return {
                searchFilter: null,
                itemContext: null,
            }
        },
        created() {
            this.fetchYears();
        },
        methods: {
            ...mapActions({
                fetchYears: 'years/fetch',
                deleteYears: 'years/delete'
            }),
            showNewItemForm() {
                this.currentItemEditMode = false;
                this.$refs.Edityear.setItem({});
                this.$refs.Edityear.showModal();
            },
            editItem(item) {
                this.$refs.Edityear.setItem(item);
                this.$refs.Edityear.showModal();
                this.$refs.Edityear.enterEditMode();
            },
            requestConfirmation(item) {
                this.itemContext = item;
                this.$refs.confirmationModal.show();
            },
            executeDelete() {
                this.$refs.confirmationModal.hide();
                this.$store.commit('loading/setLoading', true);
                YearService.delete(this.itemContext.id)
                    .then(response => {
                        this.$store.commit('loading/setLoading', false);
                        if(!response.data.success) {
                            this.$notify({
                                group: 'global',
                                title: this.$t('scdda_admin.years.error_title'),
                                type: 'error',
                                text: response.data.message
                            });
                        }

                        this.itemContext = null;
                        this.reload();
                    });
            },
            reload() {
                this.fetchYears();
            },
        },
        computed: {
            ...mapGetters({
                hasRole: 'auth/hasRole',
                years: 'years/years'
            }),
            fields() {
                return [
                    //{ key: 'id',            label: 'Id'               , sortable: true  },
                    { key: 'year',          label: this.$t('scdda_admin.years.col_year') , sortable: true  },
                    //{ key: 'year_visible',  label: 'Jahr sichtbar'     , sortable: true  },
                    { key: 'actions',       label: this.$t('scdda_admin.years.col_actions') },
                ];
            },
        }
    }
</script>