import axios from 'axios';

export default {
    list() {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/spend/list')
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    fetch(yearId) {
        return axios.get(window.applicationSettings.apiEndpoint + '/risk/spend/fetch/' + yearId)
            .then(response => response.data)
            .catch(error => error.response);
    },
    create(payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/spend/create', formData)
            .then(response => response.data)
            .catch(error => error.response);
    },
    update(yearId, payload) {
        var formData = new FormData();
        formData.append('data', JSON.stringify(payload));
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/spend/update/' + yearId + '/' + userId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    delete(yearId) {
        return axios.delete(window.applicationSettings.apiEndpoint + '/risk/spend/delete/' + yearId + '/' + userId)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    setEnabledForSupplier(userId, yearId, enabled) {
        const payload = {
            year_id: yearId,
            user_id: userId,
            enabled: enabled,
        }
        return axios.post(window.applicationSettings.apiEndpoint + '/risk/spend/setenabled', payload)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    }
}