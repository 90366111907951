<template>
    <div class="card" style="border-width: 0px">
        <div class="card-header px-0" style="background-color: #f4f5fa">{{ $t("activitylog.title") }}</div>
        <div class="card-body px-0" style="background-color: #f4f5fa">
            <b-card class="mb-3 shadow">
                <b-button v-b-toggle.help_activitylog pill size="sm" variant="outline-secondary" class="info-button"><em>i</em></b-button>
                <b-sidebar right id="help_activitylog" :title="$t(`help.info`)" backdrop-variant="dark" backdrop shadow width="600px" max-width="100%">
                    <div class="px-3 py-2">
                        <b-list-group>
                            <b-list-group-item>{{ $t("help.activitylog_1") }}</b-list-group-item>
                            <b-list-group-item>{{ $t("help.activitylog_2") }}</b-list-group-item>
                            <b-list-group-item>{{ $t("help.activitylog_3") }}</b-list-group-item>
                        </b-list-group>
                    </div>
                </b-sidebar>
                <br />
                <div class="table-scrollable">
                    <b-table striped hover :items="provideEntries" :fields="fields" :current-page="currentPage">
                    </b-table>
                </div>
                <b-pagination :total-rows="totalEntries" v-model="currentPage" :per-page="50" align="center">
                </b-pagination>
            </b-card>
        </div>
    </div>
</div>
</template>
<script>
import activitylogService from '../../../../services/ActivityLogService';

export default {
    name: 'activitylog-list',
    data() {
        return {
            totalEntries: 0,
            currentPage: 1,
        }
    },
    created() {
    },
    watch: {
    },
    methods: {
        provideEntries(ctx, cb) {
            this.$store.commit('loading/setLoading', true);
            let offset = 0;

            if(ctx.currentPage > 1) {
                offset = (ctx.currentPage - 1) * 20;
            }

            return activitylogService.provide(offset)
                .then(response => {
                    this.totalEntries = response.total;
                    this.$store.commit('loading/setLoading', false);
                    return (response.entries);
                })
                .catch(error => {
                    this.$store.commit('loading/setLoading', false);
                    return [];
                })
        }
    },
    computed: {
            fields() {
                return [
                    {
                        key: 'created_at',
                        label: this.$t(`activitylog.created_at`)
                    },
                    {
                        key: 'user',
                        label: this.$t(`activitylog.user`),
                        sortable: true
                    },
                    {
                        key: 'company',
                        label: this.$t(`activitylog.company`),
                        sortable: true
                    },
                    {
                        key: 'product_group',
                        label: this.$t(`activitylog.product_group`),
                        sortable: true
                    },
                    {
                        key: 'action_type',
                        label: this.$t(`activitylog.action`)
                    },
                    {
                        key: 'parameter',
                        label: this.$t(`activitylog.parameter`)
                    }
                ];
            }
    }
}
</script>
