import Vue from 'vue';
import axios from 'axios';

export default {
    importUser(file) {
        var formData = new FormData();
        formData.append('file', file);

        return axios.post(window.applicationSettings.apiEndpoint + '/import/user', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    importSupplierSpend(file) {
        var formData = new FormData();
        formData.append('file', file);

        return axios.post(window.applicationSettings.apiEndpoint + '/import/supplierspend', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    importAuction(file) {
        var formData = new FormData();
        formData.append('file', file);

        return axios.post(window.applicationSettings.apiEndpoint + '/import/auction', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    importCallForBids(file) {
        var formData = new FormData();
        formData.append('file', file);

        return axios.post(window.applicationSettings.apiEndpoint + '/import/callforbids', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    importAuctionItem(file, auctionId) {
        var formData = new FormData();
        formData.append('file', file);

        return axios.post(window.applicationSettings.apiEndpoint + '/import/auctionitem/'+auctionId, formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    importContract(file) {
        var formData = new FormData();
        formData.append('file', file);

        return axios.post(window.applicationSettings.apiEndpoint + '/import/contract', formData)
            .then(response => response.data)
            .catch(error => Promise.reject(error.response));
    },
    downloadTemplate(filename) {
        return axios.get('files/'+filename)
            .then(response => response)
            .catch(error => Promise.reject(error.response));    }
}
